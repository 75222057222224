@import url('https://fonts.googleapis.com/css2?family=Poppins');


input,
input.form-control {
  border: none;
  font-family: Poppins;
  position: relative;
  z-index: 0;
  border: none;
}

.form-text {
  padding: 0rem 1rem;
}

.input-arrow-down {
  top: 25px;
  right: 25px;
}

.form-group.select-input {
  margin-bottom: 0px;
}

.select-input-options {
  margin-top: 8px;
  padding: 15px 0px;
}

.select-input-options li:hover{
  background-color: #EFEFEF;
}






