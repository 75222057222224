.home-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  gap: 24px;
  width: 265px;
  max-width: 100%;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 32px;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.07);
}

/* PROFILE COMPLETION CARD */
.home-card__user-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #67b3ad;
  align-items: center;
  justify-content: center;
}

.home-card ul,
.home-card li {
  list-style: none;
}

.home-card ul.settings__bullet-list {
  padding-left: 0;
}

.profile-completion__settings-info {
  width: 233px;
  max-width: 100%;
}

.profile-completion__settings-info .text-description {
  color: #737272;
}

/* VERSATILE LINK CARD */
.versatile-card {
  text-align: center;
}

.versatile-card .title {
  align-self: flex-start;
}

.versatile-card button.button.outline-btn.active {
  border: 1.5px solid #080707;
}

/* COVERAGE CARD */
.coverage-card {
  align-items: flex-start;
}

/* HANDL INFO CARD */
.handl-info-container p {
  margin-bottom: 2px;
}

/* INSURANCE SEARCH CARD */
.insurance-search-card {
  width: 587px;
  max-width: 100%;
  background: #018077;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  color: white;
  padding: 24px 16px;
  gap: 24px;
}

.insurance-search-card p {
  color: white;
}

/* ARTICLE CARD */

.article-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px 19px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  width: 100%;
  margin-bottom: 48px;
}

.article-card-container__img {
  height: 174px;
  width: 555px;
  max-width: 100%;
  object-fit: cover;
  gap: 8px;
  border-radius: 10px;
  margin-bottom: 16px;
}

.article-card-container_author-section {
  display: flex;
  align-items: center;
  gap: 12px;
}

.article-card .slider-control-bottomcenter li {
  margin-inline: 6.67px;
}

.article-card .slider-control-bottomcenter ul {
  top: 0;
}

.article-card .article-card__see-all-btn {
  margin-top: 42px;
}
/* NEW APPOINTMENT */
.homefeed .new-appointment__paragraph {
  width: 555px;
  max-width: 100%;
  padding-inline: 16px;
}
@media screen and (max-width: 768px) {
  .handl-info-container,
  .article-card__text-container {
    padding-inline: 24px;
  }
  .article-card .slider-container {
    padding-inline: 6px;
  }
}
