.likertLegend {
  font-size: 48px;
  line-height: 53px;
  font-family: 'Crimson Pro', 'Times New Roman', Times, serif;
  font-weight: 900;
  padding-bottom: 24px;
}

.likertText {
  font-family: 'Poppins';
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  margin-top: 20px;
}

.likertResponse:nth-child(1) .likertIndicator {
  background-image: url("https://img.icons8.com/emoji/48/000000/angry-face-emoji--v2.png");
  width: 48px;
  height: 48px;
  border: none;
  background-color: transparent;
  bottom: -10px;
}

.likertResponse:nth-child(2) .likertIndicator {
  background-image: url("https://img.icons8.com/emoji/48/000000/neutral-face.png");
  width: 48px;
  height: 48px;
  border: none;
  background-color: transparent;
}

.likertResponse:nth-child(3) .likertIndicator {
  background-image: url("https://img.icons8.com/emoji/48/000000/cowboy-hat-face.png");
  width: 48px;
  height: 48px;
  border: none;
  background-color: transparent;
}

.likertResponse > input:checked + .likertIndicator {
  background-color: black;
}

.share-feedback button.button {
  margin-top: 24px;
}

@media only screen and (max-width: 768px) {
  .likertLegend {
    font-size: 30px;
    line-height: 33px;
  }

  .likertResponse {
    min-width: unset;
  }

  .likertBand {
    width: 200px;
  }
}

@media only screen and (max-width: 350px) {
  .likertText {
    font-size: 10px;
    line-height: 16px;
  }
}
