.home-container {
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.2),
      rgba(219, 225, 241, 0.2)
    ),
    #ffffff;
  padding: 32px 40px;
}
.grid-container {
  display: grid;
  grid-template-columns: minmax(220px, 1fr) minmax(480px, 2fr);
  gap: 42px;
}

.grid-card {
  background-color: transparent;
  border-radius: 8px;
  width: 589px;
  max-width: 100%;
  position: relative;
  height: fit-content;
  margin-bottom: 32px;
}

.absolute-btn {
  position: absolute;
  right: 5%;
  top: 15%;
}

.homefeed__input-container {
  padding-bottom: 16px;
  padding-inline: 16px;
  background-color: white;
}

.green-fill {
  background: rgba(103, 179, 173, 0.2) !important;
  border: 1.5px solid #018077;
  border-radius: 20px;
}

.homefeed .input-container div input {
  padding-right: 9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.homefeed {
  width: 894px;
  max-width: 100%;
  margin: auto;
}
@media screen and (max-width: 768px) {
  .home-container {
    padding: 0;
  }
  .grid-container {
    grid-template-columns: 1fr;
  }

  .grid-card {
    margin-inline: auto;
  }

  .homefeed .input-container div input {
    padding-right: 8rem;
    text-overflow: initial;
  }
  .absolute-btn {
    bottom: 15%;
    top: auto;
  }
  .homefeed-right-container {
    width: 100vw;
    max-width: 100%;
    margin-top: 49px;
  }

  .mobile-stats-card-container {
    height: 178px;
    position: relative;
    bottom: 2%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .mobile-stats-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 16px;
    width: 343px;
    background: #ffffff;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    position: relative;
    bottom: 2%;
  }
}
