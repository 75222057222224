@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@800);
@import url(https://fonts.googleapis.com/css2?family=Crimson+Pro);
@import url(https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@600);
@import url(https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@700);
@import url(https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@200);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background-color: #f8f9fa;
  min-height: 100%;
  /* overflow-x: hidden; */
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* TEXT STYLES */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Crimson Pro", "Times New Roman", Times, serif;
  margin: 0;
  letter-spacing: normal;
}

h1.large-heading {
  font-size: 60pt;
  line-height: 67pt;
}

/* h2 {
  font-size: 48px !important;
  line-height: 53px !important;
} */

h3 {
  font-size: 43px !important;
  line-height: 48px !important;
}

h4 {
  font-size: 30px !important;
  line-height: 33px !important;
}

h5 {
  font-size: 2.25rem !important;
}

a {
  cursor: pointer;
}

p,
a {
  font-family: "Poppins";
  color: black;
}

.bold {
  font-weight: bold;
}

.text-large {
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.13px;
}

.secondary-bold {
  font-size: 20px;
  line-height: 153.5%;
}

.text-medium {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.6px;
}

.text-subheading {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

.text-description {
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 23px;
}

.text-small {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
}

.text-xsmall {
  font-size: 10px;
  letter-spacing: 1.5px;
  line-height: 16px;
  margin: 0px !important;
}

.body-1 {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
}

.body-2 {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.body-3 {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.25px;
}

.heading-poppins {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.132px;
}

.subheading-poppins {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.heading-crimson-pro {
  font-family: "Crimson Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.353796px;
  color: #000000;
}

.subheading-crimson-pro {
  font-family: "Crimson Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 27px;
  letter-spacing: 0.353796px;
  color: #000000;
}

.center-text {
  text-align: center;
}

.visible {
  visibility: visible;
}

.uppercase {
  text-transform: uppercase;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-bottom-4 {
  margin-bottom: 4px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}
/* MULTIUSE STYLES */
.pointer {
  cursor: pointer;
}

li::after {
  display: none;
}

.back-arrow {
  border: solid black;
  border-width: 0 2.5px 2.5px 0;
  display: inline-block;
  padding: 2.5px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  height: 2.5px;
  width: 2.5px;
  position: relative;
  z-index: 995;
  cursor: pointer;
}

.arrow-down {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid black;
  z-index: 0;
}

.arrow-right {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.color-262525 {
  color: #262525;
}

.color-070707 {
  color: #070707;
}

.dark-gray {
  color: #333333;
}

.dark-blue {
  color: #294aa0;
}
.default-blue {
  color: #4c6bbb;
}

.bg-light-blue {
  background-color: #dbe1f1;
}

input,
input.form-control {
  border: none;
  font-family: Poppins;
  position: relative;
  z-index: 0;
  border: none;
}

.form-text {
  padding: 0rem 1rem;
}

.input-arrow-down {
  top: 25px;
  right: 25px;
}

.form-group.select-input {
  margin-bottom: 0px;
}

.select-input-options {
  margin-top: 8px;
  padding: 15px 0px;
}

.select-input-options li:hover{
  background-color: #EFEFEF;
}







.handl-logo {
  margin: 0px;
  font-weight: 900;
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav-link {
  margin-left: 10px;
  margin-bottom: 0px;
  cursor: pointer;
  text-decoration: none;
}

.dropdown-item:active {
  background-color: transparent !important;
}

a {
  text-decoration: none;
}

a::hover {
  text-decoration: none;
}

.nav-link::hover {
  text-decoration: none;
}

.nav-link-text {
  margin-bottom: 0px;
}

.navbar {
  max-width: 100vw;
}

.mobile-header.bg-white {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
}

/* header for a different color  */
.mobile-header.bg-blue {
  background-color: #b7c4e4;
}
.header {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
}

.white-arrow {
  border-color: white !important;
}

.back {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
}

.quote-navigation {
  padding: 80px 40px 40px 40px;
  width: 100%;
}

.back-text {
  padding-left: 24px;
  margin-bottom: 0px;
  margin-top: -4px;
}

#resources-dropdown-toggle.btn-primary {
  color: black;
  font-family: "Poppins";
}

#mobile-dropdown-toggle.dropdown-toggle::after,
#resources-dropdown-toggle.dropdown-toggle::after {
  display: none !important;
}
#mobile-dropdown-toggle.blue,
#mobile-dropdown-toggle.blue:focus,
#mobile-dropdown-toggle.blue:active,
#resources-dropdown-toggle.blue,
#resources-dropdown-toggle.blue:focus,
#resources-dropdown-toggle.blue:active {
  background-color: #b7c4e4;
  border-color: #b7c4e4;
  box-shadow: none;
}

#mobile-dropdown-toggle.white,
#mobile-dropdown-toggle.white:focus,
#mobile-dropdown-toggle.white:active,
#resources-dropdown-toggle.white,
#resources-dropdown-toggle.white:focus,
#resources-dropdown-toggle.white:active {
  background-color: white;
  border-color: white;
  box-shadow: none;
}

#mobile-dropdown-toggle.dark,
#mobile-dropdown-toggle.dark:focus,
#mobile-dropdown-toggle.dark:active {
  background-color: #333333;
  border-color: #333333;
  box-shadow: none;
}

#mobile-dropdown-toggle.black,
#mobile-dropdown-toggle.black:focus,
#mobile-dropdown-toggle.black:active {
  background-color: #080707;
  border-color: #080707;
  box-shadow: none;
}

.mobile-header .dropdown .dropdown-menu {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  right: 0px;
  top: 50px;
  left: unset;
}

.mobile-header.bg-black {
  background-color: #080707;
}

a.dropdown-item a {
  color: black;
}

.handl-logo.dark,
.handl-logo.black {
  color: white;
}

.back-arrow.quote-navigation-arrow {
  position: absolute;
  left: 40px;
  top: 82px;
}

.dark-background {
  background-color: #333333 !important;
}

/* USER NAVIGATION */

.navigation-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #dbe1f1;
  display: flex;
  align-items: center;
  height: 80px;
  width: 100%;
  padding: 24px 32px;
  z-index: 9999;
}

.navigation-bar__page-links {
  display: flex;
}

.navigation-bar__user-actions {
  display: flex;
  margin-left: auto;
}

.navigation-bar__link {
  color: #080707;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.132px;
  padding: 0px 20px;
}

.navigation-bar__page-links button.navigation-bar__dropdown {
  background-color: transparent;
  color: #080707;
  border: none;
  box-shadow: none;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.132px;
  padding: 0px 20px;
  display: block;
}

button.navigation-bar__dropdown::after {
  display: none !important;
}

button.navigation-bar__dropdown:focus,
button.navigation-bar__dropdown:active,
button.navigation-bar__dropdown:hover,
.navigation-bar__link:hover {
  background-color: transparent;
  color: #080707;
  text-decoration: none;
}

.navigation-bar__user-actions button {
  margin-left: 32px;
}

/* SERVICE SEARCH STYLING

.header-input {
  width: 300px;
  font-family: Poppins;
}

label.service-search-input {
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.3);
  border-radius: 50px;
  height: 51px;
  padding: 5px 25px 0px 25px;
  letter-spacing: 0.5px;
  font-weight: bold;
  margin: 0px 0px;
  min-width: 300px;
}

.desktop-search-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

::placeholder { Chrome, Firefox, Opera, Safari 10.1+
  font-family: Poppins;
  color:black;
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
  opacity: 1; Firefox
}

:-ms-input-placeholder { Internet Explorer 10-11
  font-family: Poppins;
  color:black;
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
}

::-ms-input-placeholder { Microsoft Edge 
  color:black;
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
} */

/* UPDATED MOBILE NAV */

/* hamburger */
.nav-toggle {
  display: flex;
  flex-direction: column;
  grid-gap: 3px;
  gap: 3px;
  width: 22px;
}

.nav-toggle .bar {
  height: 3px;
  width: 100%;
  background-color: black;
  transition: all 100ms ease-in-out;
}
.x:nth-of-type(1) {
  transition: all 100ms ease-in-out;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform-origin: top left;
          transform-origin: top left;
  width: 25px;
}

.x:nth-of-type(2) {
  transition: all 100ms ease-in-out;
  -webkit-transform-origin: center;
          transform-origin: center;
  width: 0;
}

.x:nth-of-type(3) {
  transition: all 100ms ease-in-out;
  -webkit-transform: rotate(-45deg) translate(-3px, 3px);
          transform: rotate(-45deg) translate(-3px, 3px);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  width: 25px;
}

.updated-mobile-nav-container {
  background-color: #dbe1f1;
  min-height: -webkit-fill-available;
  width: 100vw;
  padding-top: 64px;
  padding-inline: 24px;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 999;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  transition: -webkit-transform 250ms ease-out;
  transition: transform 250ms ease-out;
  transition: transform 250ms ease-out, -webkit-transform 250ms ease-out;
  overflow: hidden;
}

.updated-mobile-nav-container__active {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}

.updated-mobile-nav-container__menu-options {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #0a2530;
  margin-bottom: 0;
}

.updated-mobile-nav-container ul {
  padding-top: 10px;
  padding-left: 12px;
  visibility: hidden;
}
.updated-mobile-nav__li-options {
  margin-bottom: 16px;
}
.updated-mobile-nav__li-options,
.navigation-bar__user-actions .navigation-bar__user-actions-btn {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #080707;
}
.updated-mobile-nav-container__menu-options:hover {
  color: #4c6bbb;
}

.updated-mobile-nav-container__help-section {
  padding-top: 40px;
}
.updated-mobile-nav-container__help-section p:nth-of-type(1) {
  margin-bottom: 32px;
}
.updated-mobile-nav-container__handl-logo {
  position: absolute;
  bottom: 0;
  right: 0;
}

.find-an-estimate-container {
  padding-top: 42px;
  padding-bottom: 42px;
  grid-gap: 22px;
  gap: 22px;
}

.line {
  width: 327px;
  height: 0px;

  /* Blue/Dark */

  border: 1px solid #4c6bbb;
}
/* DESKTOP NAVIGATION */
.navigation-bar .dropdown .btn:focus {
  box-shadow: none !important;
}
.navigation-bar .userActions-navigation__dropdown-open {
  color: #4c6bbb;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-color: #4c6bbb;
          text-decoration-color: #4c6bbb;
  text-decoration-thickness: 4px;
}

.navigation-bar .userActions-navigation__dropdown-menu {
  background: #dbe1f1;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 0px;
  top: 24px !important;
  left: 10px !important;
  border: none;
  min-width: 5em;
}

.navigation-bar .userActions-navigation__dropdown-menu .dropdown-item,
.userActions-navigation-bar__items {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #080707;
}

.navigation-bar .userActions-navigation__dropdown-menu .dropdown-item:active,
.navigation-bar .userActions-navigation__dropdown-menu .dropdown-item:hover {
  color: #080707;
  background-color: transparent;
}

.navigation-bar .userActions-navigation__dropdown-menu .dropdown-item:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-color: #080707;
          text-decoration-color: #080707;
}

.userActions-navigation__dropdown-user-information {
  display: flex;
  flex-direction: column;
}

.navigation-bar .userActions-navigation__dropdown-user-information-menu {
  background-color: #ffffff;
  padding: 24px 16px 40px;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.15);
  border: none;
  top: 10px !important;
}

.userActions-navigation__dropdown-user-information-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.25px;
  color: #080707;
  margin-bottom: 8px;
}

.userActions-navigation__dropdown-user-information a:hover {
  -webkit-text-decoration-color: rgba(54, 54, 54, 0.9);
          text-decoration-color: rgba(54, 54, 54, 0.9);
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.userActions-navigation__dropdown-user-information li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: rgba(54, 54, 54, 0.9);
  margin-bottom: 12px;
}

.userActions-navigation__dropdown-user-information button {
  margin: auto;
  font-weight: 500;
}
.userActions-navigation__dropdown-user-information button:nth-of-type(1) {
  margin-top: 16px;
}
.userActions-navigation__dropdown-user-information button:nth-of-type(2) {
  margin-top: 24px;
}

/* SEARCH ICON STYLES */
.navigation-bar__user-actions-search {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigation-bar__user-actions-search-icon {
  cursor: pointer;
}
.navigation-bar__user-actions-search-active {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.5),
      rgba(219, 225, 241, 0.5)
    ),
    #ffffff;
  position: absolute;
  z-index: -1;
}

.navigation-bar__user-actions-search-inactive {
  display: none;
}

button.button {
  border-radius: 100px;
  border: none;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: 100%;
  white-space: nowrap;
  display: block;
  margin: auto;

  font-family: 'Poppins';
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #FFFFFF;
}

button.button:hover {
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 33%);
}

button.button:active {
  -webkit-filter: brightness(0.8);
          filter: brightness(0.8);
}

button.button.submit-btn {
  font-weight: 700;
  width: 220px;
  padding: 16px 24px;
}

button.button.long-btn {
  font-weight: 500;
  width: 287px;
  padding: 8px 24px;
}

button.button.outline-btn-blue {
  border: 2px solid #4C6BBB;
  background: transparent;
  color: #080707;
  padding: 8px 24px;
}

button.button.outline-btn-red {
  border: 2px solid #A95B40;
  background: transparent;
  color: #080707;
  padding: 8px 24px;
  border-radius: 20px;
}

button.button.outline-btn-white {
  border: 1.5px solid #FFFFFF;
  background: transparent;
  color: #FFFFFF;
  padding: 8px 24px;
  border-radius: 20px;
}

button.button.submit-btn.active {
  background: #333333;
}

button.button.submit-btn.disabled {
  background: #C6C6C6;
}

button.button.long-btn.filled.active {
  background: #4C6BBB;
}

button.button.long-btn.outline.active {
  border: 1.5px solid #4C6BBB;
  background: #FFFFFF;
  color: #080707;
}

button.button.long-btn.disabled {
  background: #B0B6C6;
}

button.button.text-btn {
  background-color: transparent;
  color: black;
  font-weight: 500;
}

button.button.outline-btn.active {
  border: 1.5px solid #018077;
  background: #FFFFFF;
  color: #080707;
  padding: 8px 24px;
}

button.button.with-shadow {
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 33%);
}

button.button.open-search-btn {
  width: 100%;
  position: relative;
  padding: 12px 0px;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.6px;
  text-transform: none;
  background-color: white;
  color: black;
  font-weight: 500;
}

button.button.mobile-estimate-form-btn.active {
  background-color: #737272;
}

button.button.mobile-estimate-form-btn.disabled {
  background: #C6C6C6;
}

button.button.login-btn {
  background: #0F3544;
  width: 119px;
  height: 36px;
}



/* .button {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  margin: 0px auto;

  border: none;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.33);
  border-radius: 25px;
  background-color: #737272;
  
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.25px;
  line-height: 20px;
  color: white;
}

.buton.dark {
  background-color: #333333
}

.mobile-search {
  width: 100%;
  color: black;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.6px;
  background-color: white;
  position: relative;
  white-space: normal;
  padding: 12px 0px;
  flex-wrap: wrap;
}

.button.active {
  font-weight: 900;
  background-color: #333333;
}
 */

.user-profile-icon {
  background: #67b3ad;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.large {
  width: 60px;
  height: 60px;
}

.small {
  width: 40px;
  height: 40px;
  padding-top: 1px;
  position: relative;
  margin-bottom: 0;
}

.navigation-bar__user-actions .active-open {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.5),
      rgba(219, 225, 241, 0.5)
    ),
    #ffffff;
  position: absolute;
  z-index: -1;
}

.hidden-selected {
  display: none;
}

footer {
  overflow: hidden;
  background-color: #333333;
}

.footer-container {
  margin: auto;
  padding: 30px 30px 80px 30px;
  overflow-x: hidden;
}

.footer-row {
  display: flex;
}

.footer-icons {
  justify-content: space-evenly;
  width: 150px;
  margin: auto;
}

.footer-links {
  justify-content: space-evenly;
  max-width: 500px;
  margin: auto;
  margin-bottom: 24px;
}

span.footer-title {
  color: white;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 23px;
}

a.footer-link {
  color: white;
  text-decoration: none !important;
  font-size: 13px;
  letter-spacing: 0.25px;
  line-height: 20px;
}

a.footer-link:hover {
  color: white;
  text-decoration: none !important;
  font-size: 13px;
  letter-spacing: 0.25px;
  line-height: 20px;
}

@media only screen and (max-width: 768px) {

  .footer-container {
    padding: 30px 30px 40px 30px;
  }

  .footer-links {
    flex-direction: column;
    text-align: center;
  }

}


.contact-form {
  margin-top: 16px;
}

.contact-input-label {
  position: absolute;
  font-weight: bold;
  z-index: 995;
  left: 23px;
  top: 5px;
}

.contact-input.form-control {
  background-color: white;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.3);
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
  min-height: 51px;
  padding: 15px 22px 5px 22px;
  border-radius: 24px;
  margin-top: 24px;
}

.contact-input.form-control:focus {
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.3);
  border: none;
}

button.button.contact-btn {
  margin-bottom: 40px;
  margin-top: 12px;
}

.captcha-text {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

#thank-you-container {
  display: none;
  width: 100%;
  text-align: center;
  margin: 40px 0px;
}

.thank-you-text {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.17px;
  line-height: 39px;
  margin: 0px;
  font-weight: semi-bold;
  padding-bottom: 24px;
}

.contact-heading{ 
  margin-top: 40px;
}

@media only screen and (max-width: 768px) {

  .contact-heading { 
    font-size: 48px !important;
    line-height: 53px !important;
  }

  .contact-page { 
    margin: 70px 12px 120px 12px;
  }


}

.about-us--1,
.about-us--2, 
.about-us--3 {
  padding: 100px 0px;
}

.about-us--1 {
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 80%;
  
}

.about-us--2 {
  text-align: center;
  background-color: #080707;
}

.about-us--2 p {
  color: white;
}

.about-us--2 section {
  max-width: 600px;
  margin: auto;
}

.about-us--2 section .about-us-heading,
.about-us--2 section .text-large {
  letter-spacing: 1px;
}

.about-us--3 section {
  max-width: 750px;
  text-align: center;
  margin: auto;
}

.about-us-heading {
  font-size: 43px;
  line-height: 48px;
  font-weight: 500;
  padding-bottom: 40px;
}

.about-us-heading-container {
  width: 450px;
}

.about-us-text {
  width: 550px;
}

@media only screen and (max-width: 768px) {

  .about-us--1,
  .about-us--2, 
  .about-us--3 {
    padding: 100px 40px;
  }

  .about-us--1 {
    flex-direction: column;
    max-width: 100%;
  }

  .about-us--1 .about-us-heading { 
    font-size: 36px;
  }

  .about-us-heading-container,
  .about-us-text {
    width: 100%;
  }
  

}

.glossary {
  padding-top: 40px;
  padding-bottom: 40px;
}

.glossary-body {
  display: grid;
  grid-template-columns: 375px 1fr;
  grid-column-gap: 24px;
  -webkit-column-gap: 24px;
          column-gap: 24px;
}

.glossary-description {
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.13px;
}

.glossary-term__term {
  font-weight: 700;
}

.glossary-search-input.form-control {
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
  border-radius: 24px;
  margin: auto;
  padding: 10px 15px;
  height: auto;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.3);
}

.glossary-search-input.form-control:focus {
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.3);
  border: none;
}

@media only screen and (max-width: 768px) {

  .glossary-body {
    display: block;
    padding: 0px 40px;
  }

  .glossary-heading {
    font-size: 48px !important;
    line-height: 53px !important;
    padding: 70px 40px 24px 40px;
  }

  .glossary-information {
    margin-bottom: 40px;
  }

  .glossary-description {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.6px;
  }

  .glossary {
    padding-top: 0px;
    padding-bottom: 100px;
  }


}

.loading {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-start-loading {
  justify-content: flex-start;
}

.handl-logo{
  margin: 0px;
  font-weight: 900;
}

/* p {
  font-family: 'Poppins';
  margin: 0;
  color: black;
}

.bold {
  font-weight: bold !important; 
}

.extra-bold {
  font-weight: 800;
}

.cost-estimate-text {
  margin-bottom: 0.5rem;
}

.x-small {
  font-size: 10px;
  letter-spacing: 1.5px;
  line-height: 16px;
}

.small-text {
  font-size: 13px;
  letter-spacing: 1.25px;
  line-height: 20px;
}

.medium {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.6px;
}

.large {
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.13px;
}

.x-large {
  font-size: 28px;
  line-height: 42px;
  letter-spacing: 0.17px;
}

.keyword {
  background-color: #333333;
  width: fit-content;
  color: white;
  border-radius: 17.5px;
  padding: 5px 15px;
  height: 25px;
  margin-bottom: 10px;
  margin-right: 10px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.dark {
  color: #080707;
  margin-bottom: 0px;
}

.input-hint {
  font-size: 14px;
  font-weight: 500;
  color: silver;
}
 */


.service__list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 24px;
  border-bottom: 0.5px solid #D8D8D8;
  background-color: white;
}

.service__list-item.transparent-bg {
  background-color: transparent;
}

.service__list-item.highlighted {
  background-color: #EFEFEF;
}

.service__list-item-overline {
  font-family: Poppins;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  margin: 0px;
}

.service__list-item-centerline {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0.132px;
  margin-top: 4px;
  margin-bottom: 0px;
}

.service__list-item-underline {
  font-family: Poppins;
  font-size: 15px;
  line-height: 22px; 
  letter-spacing: 0.4px;
  margin-top: 4px;
  margin-bottom: 0px;
}

.service__arrow {
  width: 6px;
  height: 100%;
  border: solid #080707;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-left: 32px;
}

@media only screen and (max-width: 768px) {
  .service__list-item {
    padding: 12px 0px;
  }

  .padding-adjustment {
    padding: 16px;
  }

  .service__list-item-overline { 
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
  }

  .service__list-item-centerline {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.132px;
  }

  .service__list-item-underline {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.4px;
  }
}

.search-page {
  background-color: #f8f9fa;
  height: 100%;
}

.search-page__service-groups-list,
.search-page__most-searched-list {
  margin: 0;
  padding: 0;
}

.search-page__most-searched-title {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.6px;
  font-weight: 900;
  padding: 12px;
  margin: 0;
}

.search-page__service-input div {
  width: 100%;
}

.search-page__service-input input {
  width: 100%;
  border: none;
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.5);
  padding: 40px 0px 20px 80px;
  background-color: white;
  

  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
}

.search-page__service-input input::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #979797;
  opacity: 1; /* Firefox */
}

.search-page__service-input input:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #979797;
  opacity: 1; /* Firefox */
}

.search-page__service-input input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #979797;
  opacity: 1; /* Firefox */
}

.search-page__back-arrow {
  border: solid black;
  border-width: 0 2.5px 2.5px 0;
  display: inline-block;
  padding: 2.5px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  height: 2.5px;
  width: 2.5px;
  margin-right: 40px;

  position: absolute;
  z-index: 999;
  top: 50px;
  left: 40px;
}

.search-icon {
  position: absolute;
  right: 40px;
  top: 45px;
}

.service-list-item.service-serch-list-item {
  padding: 16px;
}

.article__article-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 66px;
  letter-spacing: 0.6px;
  margin-top: 48px;
  margin-bottom: 16px;
}

.article__article-subtitle {
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.6px;
  margin-bottom: 64px;
}

.article__article-width {
  max-width: 960px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.article__article-author {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.article__author-name {
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.6px;
  margin-bottom: 32px;
}

.article__article-author .article__author-name {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 0px;
}

.article__article-author .article__author-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 0px;
}

.article__author-icon {
  background-color: #C4C4C4;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin-right: 16px;
}

.article__article-date {
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.25px;
  margin-top: 56px;
  margin-bottom: 8px;
}

.article__article-content {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.6px;
  margin-bottom: 40px;
}

.article__article-image-container {
  position: relative;
  overflow: hidden;
  /* height: 432px; */
}

.article__article-image {
  width: 100%;
}

.article__article-sources {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.6px;

  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.article__article-sources a {
  font-style: italic;
  text-decoration: none;
  color: black;
}

.article__article-sources p {
  font-weight: 600;
  margin-bottom: 0px;
}

.article__author-bio {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.6px;
  margin-bottom: 40px;
  
}

.article__reccomended-reading {
  margin: 0px 64px 152px 64px;
}

.article__reccomended-reading .article__article-title {
  font-family: Crimson Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 71px;
  letter-spacing: 0.6px;
  padding-top: 72px;
  margin-bottom: 24px;
  border-top: solid 1px #979797;
}

ul.article-list {
  padding-left: 0px;
}

.article__article-content a {
  color: #4C6BBB;
}

@media only screen and (max-width: 768px) { 
  .article__article-image-container {
    max-height: 216px;
  }

  .article__article-title {
    font-size: 28px;
    line-height: 42px;
    letter-spacing: 0.6px;
    margin-top: 92px;
  }

  .article__article-width {
    padding: 0px 24px;
  }

  .article__reccomended-reading .article__article-title {
    padding-top: 72px;
    margin-right: 24px;
    margin-left: 24px;
  }

  .article__article-subtitle {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.5px;
    margin-bottom: 8px;
  }

  .article__article-content {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.6px;

  }

  .article__author-name {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 24px;
  }

  .article__author-icon {
    width: 48px;
    height: 48px;
  }

  .article__article-author .article__author-name {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
  }
  
  .article__article-author .article__author-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
  }

  .article__article-date {
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
  }

  .article__reccomended-reading {
    margin: 0px 8px 152px 8px;
  }

  .article__reccomended-reading .article__article-title {
    font-size: 42px;
    line-height: 47px;
    letter-spacing: 0.6px;
    padding-top: 40px;
    margin-top: 40px;
    margin-bottom: 24px;
  }
}

a.article-card__link {
  color: black;
}

a.article-card__link:hover {
  color: black;
  text-decoration: none;
}

.article-card__article-image-container {
  position: relative;
  min-width: 240px;
  height: 160px;
  overflow: hidden;
}

.article-card__article-image {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  min-height:100%;
  min-width:100%; 
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}

.article-card__article-title {
  font-size: 24px;
  line-height: 32px;
}

.article-card__body {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 24px;
  margin-bottom: 32px;
  border-radius: 8px;
}

.article-card__article-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 8px;
  letter-spacing: 0.6px;
}

.article-card__article-date-and-keywords {
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.6px;

  margin-bottom: 16px;
}

.article-card__article-date-and-keywords .article-card__article-keywords {
  font-style: italic;
}

.article-card__article-author {
  display: flex;
  align-items: center;
}

.article-card__author-icon {
  background-color: #C4C4C4;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
}

.article-card__author-name {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.6px;
}

@media only screen and (max-width: 768px) {
  .article-card__body {
    flex-direction: column-reverse;
    padding: 0px;
    margin: 0px 0px 24px 0px;
    border-radius: 4px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  }

  .article-card__article-information {
    padding: 16px 24px 24px 24px;
  }

  .article-card__article-image-container {
    position: relative;
    min-width: 100%;
    height: 160px;
    overflow: hidden;
    border-radius: 4px 4px 0px 0px;
  }

  .article-card__article-image {
    position: absolute;
    left: 50%;
    top: 50%;
    height: auto;
    width: 100%;
    min-height:100%;
    min-width:100%; 
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
  }

  .article-card__article-title {
    font-size: 18px;
    line-height: 27px;
  }
}

.articles-container {
  max-width: 1204px;
  margin: auto;
}

.article-list__body {
  margin: 0px 64px;
}

.article-list__page-title {
  font-family: Crimson Pro;
  font-weight: bold;
  font-size: 64px;
  line-height: 72px;
  letter-spacing: 0.6px;

  margin: 24px 0px;
}

.article-list__subtitle {
  font-family: Poppins;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.6px;

  margin-bottom: 72px;
}

.article-list__see-more-button {
  padding: 8px 24px;
  width: 287px;
}

.article-list__filter-and-sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.article-sort{
  display: flex;
}

button.article-sort__button {
  background-color: transparent;
  color: #080707;
  box-shadow: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 0px;
  padding: 12px 32px;
}

button.article-sort__button.active-tab {
  border: 2px solid #080707;
  border-radius: 130px;
  font-weight: 600;
}

.article-filter{
  display: flex;
  position: relative;
}

.article-filter__search-icon-container {
  border-bottom: 1px solid #636363;
  padding-right: 12px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

ul.article-list__list {
  list-style-type:none;
  padding: 0px;
  margin: 48px 0px; 
  width: 100%;
}

a.article-list__article-link {
  color: black;
}

a.article-list__article-link:hover {
  color: black;
  text-decoration: none;
}

.first-article .article-list__article-image-container {
  overflow: hidden;
  display: flex;
  align-items: center;
}

.first-article .article-list__article-image{
  width: 100%;
  min-height:100%;
  min-width:100%; 
}


.first-article  .article-list__article-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  -webkit-column-gap: 24px;
          column-gap: 24px;
  margin-top: 24px;
  margin-bottom: 104px;
}

.first-article .article-list__article-information {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.article-list__article-title { 
  font-weight: 600;
  margin-bottom: 8px;
  letter-spacing: 0.6px;
}

.first-article .article-list__article-title {
  font-size: 36px;
  line-height: 48px;
}

.article-list__article-date-and-keywords {
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.6px;

  margin-bottom: 16px;
}

.article-list__article-date-and-keywords .article-list__article-keywords {
  font-style: italic;
}

.article-list__article-author {
  display: flex;
  align-items: center;
}

.article-list__author-icon {
  background-color: #C4C4C4;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
}

.article-list__author-name {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.6px;
}

.article-list__article-summary {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.6px;
}

.article-list__article-summary-read-more {
  font-weight: 600;
}


@media only screen and (max-width: 768px) {
  .article-list__body {
    margin: 60px 0px 0px 0px;
  }

  .article-list__page-title {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 0.6px;
    margin: 0px;
    padding: 24px 16px 8px 16px;
  }

  .article-list__subtitle {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.6px;
    margin: 0px;
    padding: 0px 16px 32px 16px;
  }

  .article-list__filter-and-sort {
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 16px;
  }

  button.article-sort__button {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.15px;
    padding: 8px 24px;
  }

  .article-filter {
    width: 100%;
    margin-top: 32px;
    
  }

  .article-filter div:not(.article-filter__search-icon-container)  {
    width: 100%;
  }

  .article-filter div {
    font-family: Poppins;
  }

  .article-filter div input {
    width: 100%;
    flex-grow: 1;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.15px;
  }

  .first-article  .article-list__article-details { 
    display: block;
  }

  .first-article .article-list__article-image-container {
    max-height: 280px;
  }

  .first-article .article-list__article-title {
    font-size: 22px;
    line-height: 33px;
  }

  .first-article .article-list__article-details {
    padding: 0px 16px;
    margin-bottom: 72px;
  }

  .first-article .article-list__article-date-and-keywords {
    font-size: 10px;
    line-height: 15px;
  }

  .article-list__author-name {
    font-size: 12px;
    line-height: 18px;
  }

  .article-list__article-summary {
    font-size: 12px;
    line-height: 18px;

    margin-top: 24px;
  }
}




input.form-control.contact-form__input,
textarea.form-control.contact-form__input  {
  border: 0.5px solid #000000;
  border-radius: 8px;
  font-family: Poppins;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.132px;
  padding: 12px;
}

textarea.form-control.contact-form__input  {
  min-height: 173px;
}

.contact-form__btn {
  background: #737272;
  padding: 8px 24px;
  margin-top: 40px;
  width: 287px;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 1.25px;
}

.contact-form__disclaimer {
  font-style: italic;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.132px;
  margin-top: -12px;
}

.contact-form__container .form-group label {
  display: none;
}

.contact-form__container .form-group {
  margin-bottom: 20px;
}

.contact-form__container button.button {
  margin-top: 40px;
}

.contact-form__thank-you-text {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.132px;
  margin-bottom: 60px;
}

@media only screen and (max-width: 768px) {
  .contact-form__thank-you-text {
    margin-top: 0px !important;
    margin-bottom: 40px;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.132px;
  }
}

.contact-section__contact-container{
  padding-top: 172px;
  padding-bottom: 238px;
}

.contact-section__contact-body {
  display: flex;
  flex-wrap: wrap;
}

.contact-section__contact-description,
.contact-section__contact-form {
  flex: 1 1;
}

.contact-section__contact-description {
  padding-right: 64px;
}

.contact-section__body-heading {
  font-family: Crimson Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 67px;
  margin-bottom: 0px;
  padding-bottom: 24px;
}

.contact-section__body-text {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.132px;
}

.contact-section__body-text.mobile-hide{
  display: block;
}

.contact-section__body-text.desktop-hide{
  display: none;
}

@media only screen and (max-width: 768px) {
  .contact-section__contact-container{
    padding: 96px 16px 238px 16px;
  }

  .contact-section__body-heading {
    font-size: 32px;
    line-height: 36px;
    padding-bottom: 16px;
  }

  .contact-section__body-text {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.132px;
  }

  .contact-section__body-text.desktop-hide{
    display: block;
  }

  .contact-section__body-text.mobile-hide{
    display: none;
  }

  .contact-section__contact-body input.form-control.contact-form__input,
  .contact-section__contact-body textarea.form-control.contact-form__input {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.132px;
  }
}

.input-container {
  font-family: Poppins;
  position: relative;
}

.input-container div,
.input-container label {
  width: 100%;
}

.input-container div input,
.input-container select {
  background-color: transparent;
  color: #080707;
  font-family: Poppins;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.4px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #080707;
  padding: 8px 24px 8px 16px;
  outline: none;
  border-radius: 0px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 4rem;
}

.input-container select::-ms-expand {
  display: none;
}

.input-container div input.writeable::-webkit-input-placeholder {
  color: #363636;
}

.input-container div input.writeable:-ms-input-placeholder {
  color: #363636;
}

.input-container div input.writeable::placeholder {
  color: #363636;
}

.input-container div input.read-only::-webkit-input-placeholder {
  color: #848484;
}

.input-container div input.read-only:-ms-input-placeholder {
  color: #848484;
}

.input-container div input.read-only::placeholder {
  color: #848484;
}

.input-container div input.read-only {
  border-color: #848484;
  cursor: default;
}
.input-container div input.read-only-alt {
  border-bottom: 1px solid #585858;
  color: #585858;

}
.input-and-label {
  position: relative;
  padding-top: 20px;
}

.input-container .input-and-label label {
  display: none;
  position: absolute;
  top: 0px;
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.input-container .input-and-label input.read-only:not(:-ms-input-placeholder) + label {
  display: block;
}

.input-container .input-and-label input.writeable:focus + label,
.input-container .input-and-label input.writeable:valid + label,
.input-container .input-and-label input.read-only:not(:placeholder-shown) + label {
  display: block;
}

.select-only {
caret-color: transparent;
cursor: pointer;
}

.input-container .input-and-label input.writeable:focus::-webkit-input-placeholder {
  color: transparent;
}

.input-container .input-and-label input.writeable:focus:-ms-input-placeholder {
  color: transparent;
}

.input-container .input-and-label input.writeable:focus::placeholder {
  color: transparent;
}

.input-container.placeholder-gray-bg div input,
.input-container.placeholder-gray-bg select {
  background: #EAEAEA;
}

.input-container.placeholder-gray-bg div input,
.input-container.placeholder-gray-bg select {
  padding: 8px 24px;
}

.input-container .input-icon {
  position: absolute;
  z-index: 0;
  right: 12px;
  top: 30px;
}

.input-container .input-icon .input-arrow {
  content: '';
  display: block;
  width: 0; 
  height: 0; 
  top: 36px;
  right: 24px;

  border: solid #080707;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.input-container .input-arrow.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.input-container .input-arrow.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.input-message {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  padding-top: 8px;
  padding-left: 16px;
  margin-top: -1px;
  position: absolute;
  z-index: 1;
}

.input-message.error {
  color: #D94411;
  border-top: 1px solid #D94411;
}

.input-message.error a {
  color: #D94411;

}


.input-message.valid {
  color: #018077;
  border-top: 1px solid #018077
}

.input-message.valid a {
  color: #018077;
  
}

.input-action {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.15px;
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .input-message {
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.25px;
  }
}


.press-release__page-title {
  font-family: Crimson Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 71px;
  letter-spacing: 0.6px;
  margin-bottom: 20px;
  margin-top: 40px;
}

ul.press-release-list {
  list-style-type:none;
  padding: 0px;
  margin-bottom: 60px;
}

.press-release__press-release-image-wrap {
 height: 150px;
 min-width: 150px;
 text-align: center;
}

.press-release__press-release-image {
  height: 100%;
 }

.press-release {
  display: flex;
  margin-bottom: 40px;
}

.press-release__press-release-information {
  padding-left: 20px;
}

.press-release__press-release-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.6px;
}

.press-release__press-release-summary {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.6px;
  margin: 12px 0px;
}

.press-release__press-release-link {
  color: black;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.6px;
}

@media only screen and (max-width: 768px) {
  .press-release {
    flex-direction: column;
  }
}

.sign-up__side-bar {
  background-color: #DBE1F1;
  padding: 56px 24px;
  width: 270px;
  min-width: 270px;
}

.sign-up__side-bar-text {
  position: fixed;
  width: 270px;
  padding: 0px 24px;
  left: 0;
}

.sign-up__side-bar img {
  position: fixed;
  bottom: 0;
  left: -3px;
}

.sign-up__side-bar h2 {
  font-weight: 200;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 0.353796px;
  margin-bottom: 40px;
}

.sign-up__content {
  max-width: 714px;
  margin: 0px auto;
}

.sign-up__content h1 {
  font-weight: 900;
  font-size: 43px;
  line-height: 48px;
  letter-spacing: 0.25px;
  margin-bottom: 16px;
}

.sign-up__subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
}

.consent-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: inline-block;
  margin-left: 12px;
  margin-bottom: 0;
}

.sign-up-form__input {
  margin-bottom: 48px;
}

.sign-up-form__input.with-help-text {
  margin-bottom: 56px;
}

.sign-up-form__consent-input {
  margin-top: 54px;
  margin-bottom: 48px;
  display: flex;
  justify-content: center;
}

.sign-up-form__consent-input input {
  height: 18px;
  width: 18px;
  min-width: 18px;
}

.access-code__input {
  margin-bottom: 16px;
  margin-top: 50px;
}

button.build-profile-btn {
  margin-top: 80px;
}

.sign-up-form__link {
  color: #080707;
  text-decoration: underline;
}

.access-code-helper-container {
  width: 100%;
  text-align: center;
  margin-top: 16px;
}

.access-code-helper {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.access-code-helper.sent {
  color: #018077;
}

.my-modal .modal-content {
  width: 500px!important;
  height: 319px;
  max-width: none !important;
  border-radius: 20px!important;

}
.my-modal .modal-body {
  padding-top: 0px!important;
  padding-left: 0px!important;
  padding-bottom: 0px!important;
  padding-right: 0px!important;
}
.sign-up__modal-body {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 32px;

}
.sign-up__modal-body button:first-of-type{
  margin-bottom: 24px;
}
.sign-up__modal-header{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 27px;
color: #000000;
letter-spacing: 0.5px;
}

.sign-up__modal-text{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 24px;
margin-bottom: 56px;
/* or 160% */

letter-spacing: 0.5px;

/* Color/4. Text/Grey */

color: #363636;
}

@media only screen and (max-width: 768px) {
  .sign-up__content h1 {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.25px;
  }

  .sign-up__side-bar h2 {
    font-weight: 200;
    font-size: 24px;
    line-height: 27px;
    letter-spacing: 0.353796px;
  }

  .sign-up__side-bar-text {
    position: absolute;
    width: 160px;
    padding: 0px;
    left: 16px;
  }
  
  .sign-up__side-bar img {
    position: absolute;
    height: 250px;
    width: 250px;
    bottom: -24px;
    right: -20px;
    left: unset;
  }

  .sign-up-form__consent-input {
    margin-top: 50px;
    margin-bottom: 44px;
  }

  .sign-up__subtitle {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .sign-up__modal-body {
    padding: 8px 8px 16px;
  }
}


.sidebar-layout {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100%;
  display: block;
}

.sidebar-layout__body-default {
  display: flex;
  min-height: calc(100vh - 80px);
}

.sidebar-layout__body-sign-in {
  display: flex;
  min-height: 80vh;
}

.sidebar-layout__sidebar {
  position: relative;
  z-index: -1;
}

.sidebar-layout__content-container {
  height: 100%;
  flex-grow: 1;
}

.sidebar-layout__content {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  padding: 64px 32px;
}

.handl-progress-bar .progress-bar {
  background-color: #0F3544CC;
  border-radius: 0;

}
@media only screen and (max-width: 768px) {
  .sidebar-layout__body-default {
    flex-direction: column;
    min-height: calc(100vh - 60px);
  }

  .sidebar-layout__sidebar {
    width: 100%;
    height: 280px;
    padding: 24px 16px;
    order: 2;
    overflow: hidden;
  }

  .sidebar-layout__content {
    order: 1;
    padding: 92px 16px;
    margin: 0px;
  }

  .handl-progress-bar  {
    position: fixed;
    top: 55px;
    right:0;
    left: 0;
  
  }
}

.close-modal {
  border-radius: 16px !important;
  position: absolute;
  right: 10px;
  top: 10px;
}

.sign-in__content h1 {
  font-weight: 900;
  font-size: 43px;
  line-height: 48px;
  letter-spacing: 0.25px;
  margin-bottom: 16px;
  color: #080707;
  white-space: nowrap;
}

.sign-in__subtitle {
  color: #D94411;
  font-weight: 400;
  font-size: 18px;
  min-height: 27px;
  letter-spacing: 0.5px;
  margin-bottom: 28px;
}

.sign-in__content {
  max-width: 714px;
  width: 65vw;
  margin: auto;
}


.sign-in-form__input {
  margin-bottom: 32px;
}

.sign-in-form__remember-me {
  display: flex;
  align-items: center;
}

.sign-in-form__remember-me input {
  height: 18px;
  width: 18px;
  min-width: 18px;
}

.sign-in-form__remember-me p {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  display: inline-block;
  margin-left: 12px;
  margin-bottom: 0;
}

.sign-in-form__remember-me-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.sign-in-btn {
  margin-top: 80px;
  margin-bottom: 32px;
}

.sign-in__outline-btn > button.button.outline-btn.active{
  background-color: transparent;
}

.sign-in__or-element {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}

.sign-in__or-element span {
  margin: 0px 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: rgba(54, 54, 54, 0.6);
}

.sign-in__or-bar {
  width: 146px;
  height: 1px;
  background-color: rgba(54, 54, 54, 0.6);
}

.sign-in__privacy-link {
  color: #080707;
  display: flex;
  justify-content: center;
  font-family: Poppins;
  font-style: Regular;
  font-size: 11px;
  line-height: 17px;
  line-height: 100%;
  vertical-align: top;
  margin-bottom: 8px;
  text-decoration: underline;
  cursor: pointer;
}

.sign-in__privacy-link:hover, .sign-in__privacy-link:visited {
  color: #080707;

}



@media only screen and (max-width: 768px) {
  .sign-in__design {
    display: none;
  }

  .sign-in__content {
    width: 100%;
  }

  .sign-in__content h1 {
    margin-bottom: 8px;
    white-space: normal;

  }

  .sign-in__subtitle {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
  }

  .sign-in__sidebar {
    display: none;
  }
}

.sign-in__design-blue {
  position: absolute;
  overflow: hidden;
  height: 105%;
}

.sign-in__design-blue svg {
  width: 32vw;
  height: 100%;
}

.sign-in__design-reset-image {
  position: absolute;
  left: 2vw;
}
.sign-in__design-default-image {
  position: absolute;
  bottom: 10px;
  left: 2vw;
  max-width: 200px;
}
.booking-complete__bottom-minion {
  position: absolute;
  bottom: 15%;
  left: 5vw;
}

.FAQ__accordian-icon {
    font-size: 25px;
    font-weight: 500;
  }
  .FAQ__accordian-footer, .FAQ__accordian-footer .accordion, .FAQ__accordian-footer .card {
    background-color: #EEEFF2;
    ;
  
  } 
  .FAQ__accordian-footer p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    margin-inline: 32px;
    padding-top: 32px;
    margin-bottom: 12px;
  }
  
  .FAQ__accordian-card-title.card {
    font-family: 'Poppins';
    padding: 20px 32px;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1) !important;
  }
  
  .FAQ__accordian-card-title .FAQ__accordian-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
.forgot-password-form button.long-btn {
  margin-top: 104px;
  margin-bottom: 120px;
}
.forgot-password-form__input {
  margin-bottom: 56px;
}

.forgot-password__error-span {
  display: flex;
  justify-content: center;
}

.onboarding__content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

  
.onboarding__landing-header {
    margin-bottom: 61px;
    margin-top:24px;
    font-family: 'Crimson Pro';
    font-style: normal;
    font-weight: 200;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.353796px;
    color: #080707;
    

}

.onboarding__landing-header-secondary {
    margin-bottom: 24px;
    margin-top: 16px;
    font-family: 'Crimson Pro';
    font-style: normal;
    font-weight: 200;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.353796px;
    color: #080707;
    

}
.onboarding__content-box .width-694 {
    max-width: 694px;
}

/* notifications */
.onboarding__notifications-content-box {
    max-width: 634px;
    margin: 0px auto;
    margin-top: 48px;
    margin-bottom: 88px;
}

.onboarding__notifications-subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 8px;
    letter-spacing: 0.15px;
    
    color: #000000;
}
.notifications-subtitle{
    margin-bottom: 24px;

}
.onboarding__notification-option {
    margin-bottom: 32px;
}

.onboarding__content-box {
    max-width: 754px;
    margin: 0px auto;
  }

.onboarding__container-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    padding-inline: 9px;

}
.onboarding__container-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 0.5px solid #D8D8D8;
    max-width: 559px;



    

}


.onboarding__container-row:last-of-type {
    margin-bottom: 64px;
}

.onboarding__container-icon {
    margin: 1.75rem 1.75rem 1.75rem 0;
}
.onboarding__container-textbox {
    display: flex;
    flex-direction: column;
  

}
.onboarding__container-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.132px;

    color: #000000;
    margin-bottom: 0;
}

.txt-fw {
    font-weight: 500;
}
.onboarding__container-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: 0.132px;

    color: #000000;

    margin-bottom: 21px;
}


.onboarding__landing-footer-container {
    position: relative;
    margin-top: 300px;
    width: 98vw;
    max-height: 25vh;
    display: none;
}
.onboarding__landing-footer-container-stepFive {
    position: relative;
    margin-top: 16px;
    width: 99vw;
    max-height: 25vh;
}

.onboarding__landing-minion {
    position: absolute;
    bottom: 0;
    right: 8%;
    max-width: 100%;
}
.onboarding__landing-wave {
    width: 98vw;
    max-height: 25vh;
    
}

.onboarding__landing-mobile-minion {
    width: 100vw;
}

.onboarding__textbox-primary .body-1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;

    /* Color/4. Text/Black */

    color: #080707;
}

ul.onboarding__bullet-list {
    list-style-type: disc;
    padding-left: 2em;
    margin-bottom: 40px;
}

ul.onboarding__bullet-list p {
    display: inline-block;
    position: relative;
    left: -4px;
  }

.landing__final-p {
    margin-bottom: 80px;
}

.onboarding__step1-form {
    max-width: 636px;
    margin-inline: auto;
    margin-bottom: 80px;
    margin-top: 40px;
}

.onboarding__custom-text-button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #4C6BBB;
    background-color: transparent;
    border: none;
    display: block;
    margin: auto;
    margin-bottom: 40px;
    cursor: pointer;
}

.onboarding__custom-text-button p:not(.location-text-enabled) {
    color: #4C6BBB;
    

}
.onboarding__custom-button {
    border: 1.5px solid #018077;
    border-radius: 20px;
    padding: 8px 24px;
    background-color: transparent;
    cursor:pointer;
    margin: auto;
    display: block;

    font-size: 13px;
    line-height: 20px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;

    /* Color/4. Text/Black */

    color: #080707;
    margin-top: 68px;
}
.disabled {
    color: #868686;
    border: 1.5px solid #C6C6C6;
    cursor: not-allowed;
}

.location-text-enabled {
    color: #018077;
}
/* step 5 */
.onboarding-body-1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* or 160% */

    letter-spacing: 0.5px;

    /* Color/4. Text/Black */

    color: #080707;
}
.onboarding__step5-content {
    margin-bottom: 42px;
}
.onboarding__step5-subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.5px;
    margin-top: 40px;
    margin-bottom: 32px;
    color: #000000;
    
}
.onboarding__checkup-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.132px;
    /* this will change once the facility text is added to the bottom */
    /* margin-bottom: 55px;  */

    color: #000000;
}

.onboarding__step5-content-box {
    justify-content: space-between;
    border-bottom: 0.5px solid #D8D8D8;
    margin-bottom: 16px;
}   
.onboarding__best-price {
    padding: 4px 16px;
    background-color: #DBE1F1;
    border-radius: 20px;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #080707;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-right: 19.88px;    
}
.onboarding__cpt-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    margin-bottom: 3px;
    color: #000000;
}
.onboarding__passavant {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.4px;

    color: #262525;
}

.onboarding__button {
    z-index: 5;
}
.FAQ-link, .FAQ-link:hover {
    color: inherit;
    text-decoration: underline;
}
/* desktop */

@media only screen and (min-width: 768px) {
    .onboarding__landing-header {
        font-family: 'Crimson Pro';
        font-style: normal;
        font-weight: 200;
        font-size: 45.94px!important;
        line-height: 51px;
        margin-bottom: 52px;
        letter-spacing: 0.353796px;
        max-width: 610px;

    
    }
    .onboarding__landing-header-secondary {
        font-family: 'Crimson Pro';
        font-style: normal;
        font-weight: 200;
        font-size: 45.94px!important;
        line-height: 51px;
        margin-top: 24px;
        margin-bottom: 16px;
        letter-spacing: 0.353796px;
        max-width: 610px;
        color: #080707;
    }
    .onboarding__container-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.132px;
        color: #000000;
    }

    .onboarding__container-row {
        padding-inline: 97px;
        max-width: 754px;

    }
    
    .onboarding__textbox-primary .body-1{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.5px;
        max-width: 634px;
    
        /* Color/4. Text/Black */
    
        color: #080707;
    }
    .onboarding__landing-mobile-minion {
        display: none;
    }
    
    
    .onboarding__landing-footer-container {
        display: block;
    }

    .onboarding__notifications-subtitle {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;
        
        letter-spacing: 0.132px;
        
        color: #000000;
    }
    .onboarding__notification-option {
        max-width: 482px;
    }
    
    .txt-fw {
        font-weight: 600;
    }
    /* step five */

    .onboarding-body-1 {
        font-size: 18px;
        line-height: 27px;
    }
    .onboarding__step5-content-box {
        margin-bottom: 32px;
    }
    .onboarding__step5-content {
        max-width: 636px;
        margin: auto;
        margin-bottom: 71px;
    }
    
    .onboarding__step5-subtitle {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;
        letter-spacing: 0.132px;

        color: #000000;
    }
    .onboarding__cpt-text {
        margin-bottom: 4px;
    }

    .onboarding__checkup-text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.132px;
        margin-bottom: 8px;

        color: #000000;
    }

    .onboarding__best-price {
        padding: 4px 16px;
        background-color: #DBE1F1;
        border-radius: 20px;

        font-family: 'Poppins';
        font-style: normal;
        font-size: 14px;
        line-height: 21px;
        margin-right: 8.88px; 
    }
    .onboarding__passavant {
        font-size: 18px;
        line-height: 27px;
    }
    .onboarding__landing-footer-container-stepFive {
        margin-top: 200px;
    }
}
/* very small screens/mobile */
@media only screen and (max-width: 480px) {
    .onboarding__landing-header {
        max-width: 343px;

    }
   
}
/* minion movement control (onboarding screen 1) */
@media only screen and (max-width: 1200px) {

    .onboarding__landing-minion {
        position: absolute;
        bottom: 0;
        right: 3%;
        max-width: 100%;
    }
    }
.toggle-switch-container {
  display: flex;
  justify-content: space-between;
}
.toggle-switch {
  position: relative;
  width: 36px;
  display: inline-block;
  text-align: left;
  top: 8px;
}

.toggle-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
}
.checkbox {
  display: none;
}
.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
}
.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 20px;
  padding: 0;
  line-height: 20px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}
.inner:before {
  content: "";
  padding-left: 10px;
  background-color: #018077;
  color: #fff;
}
.inner:after {
  content: "";
  padding-right: 10px;
  background-color: #A1ADAD;;
  color: #fff;
  text-align: right;
}
.switch {
  display: block;
  width: 16px;
  height: 16px;
  margin: 2px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.checkbox:checked + .label .inner {
  margin-left: 0;
}
.checkbox:checked + .label .switch {
  right: 0px;
}

.toggle-text-disabled {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
  color: #A1ADAD;
}
.disabled {
  background-color: #F7F7F7;
  cursor: not-allowed;
}
.disabled:before {
  background-color: #B7B7B7;
  cursor: not-allowed;
    
}

.settings__side-bar {
  width: 328px;
  background: #e5e5e5;
  padding: 64px 32px;
  z-index: 0;
}

.settings__content {
  padding-left: 32px;
  padding-right: 32px;
}

.settings__page-title {
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.15px;
  margin-bottom: 24px;
}

.settings__home.settings-page h1 {
  font-weight: 200;
  font-size: 45.94px;
  line-height: 51px;
  letter-spacing: 0.353796px;
  margin-bottom: 24px;
}

.settings__side-bar a,
.settings__page-content a,
.plan-details-useful-info a {
  color: black;
  text-decoration: underline;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}

.settings-page .body-1,
.settings-page .body-2,
.settings-page .body-3 {
  margin-bottom: 8px;
}

.settings__subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
  margin-bottom: 8px;
}

.settings__content {
  max-width: 650px;
}

.settings__page-section button {
  margin: 0;
}

/* COMPLETION PROGRESS */

.settings__profile-completion-descripton {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.settings__profile-completion-text {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.home__profile-completion-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.6px;
  color: #080707;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.settings__profile-completion-percentage {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
}

.home__profile-completion-percentage {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #080707;
  margin-bottom: 8px;
}

.settings__profile-completion-bar {
  background-color: #c4c4c4;
  width: 100%;
  height: 10px;
  display: block;
  border-radius: 8px;
}

.settings__profile-completion-bar-filled {
  height: 100%;
  background-color: #4c6bbb;
  display: block;
  border-radius: 8px;
}

/* WHAT'S NEXT */

.settings__whats-next {
  margin-top: 24px;
}

.settings__whats-next p {
  margin-bottom: 8px;
}

.settings__whats-next li {
  margin-bottom: 12px;
}

/* SIDEBAR PRIVACY NOTICE */

.settings__privacy-notice {
  margin-top: 80px;
}

.settings__privacy-notice p.settings__privacy-notice-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 16px;
}

.settings__privacy-notice p {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.15px;
  margin-bottom: 8px;
}

/* BULLET LIST */

ul.settings__bullet-list {
  list-style-type: disc;
  padding-left: 1em;
}

ul.settings__bullet-list p {
  display: inline-block;
  position: relative;
  left: -4px;
}

/* HOME */

.settings___home-insurance-info {
  margin-bottom: 32px;
}

.settings__home-see-more-btn {
  margin-top: 40px;
}
.settings__home-card-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.132px;
  margin-bottom: 24px;
}

.settings__home-card {
  border-bottom: 0.5px solid rgba(135, 135, 135, 0.2);
  /* box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.5); */
  padding: 24px;
  cursor: pointer;
}

.settings__home-info {
  padding: 0px 24px;
}

.settings__home.settings-page .settings__profile-completion {
  margin-bottom: 32px;
  margin-top: 32px;
}

/* MY PROFILE */

.settings__my-profile .settings__page-title {
  margin-bottom: 30px;
}

.settings__my-profile .settings__whats-next {
  margin-bottom: 80px;
}

.settings__my-profile .settings__page-section:not(:last-child) {
  margin-bottom: 104px;
}

.settings__noneditable-data {
  padding-top: 8px;
  padding-bottom: 40px;
}

.settings__my-profile .settings__description {
  margin-bottom: 32px;
}

.settings__my-profile .settings__input {
  margin-bottom: 60px;
}

.settings__my-profile .settings__input .read-only {
  color: #363636;
}

.settings__service-preference-section {
  margin-bottom: 20px;
}

.settings__service-preference-section p {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 12px;
}

.settings__service-preference-section b {
  font-weight: 600;
}

/* NOTIFICATIONS */

.settings__notifications .settings__page-section {
  padding-top: 36px;
  padding-bottom: 32px;
}

/* PRIVACY */

.settings__deletions {
  margin-bottom: 40px;
}

.settings__deletion-section {
  margin-top: 64px;
}

.settings__privacy-card-title.card {
  padding: 20px 24px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1) !important;
}

.settings__privacy-card-title .settings__accordian-toggle {
  display: flex;
  justify-content: space-between;
}

.settings__notifications .settings__page-section:not(:last-child) {
  border-bottom: 1px solid #c6c6c6;
}

.settings__edit-password .settings__input label {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
}

.settings__edit-password .settings__input input {
  padding-top: 30px;
}

.settings__edit-password .settings__input .input-icon.input-action {
  top: unset;
  bottom: 8px;
}

.settings__edit-password .settings__input {
  margin-bottom: 48px;
}

.settings__edit-password .settings__input .read-only {
  color: #080707;
}

.settings__edit-password .settings__input.password-confirmation .read-only {
  color: #dbdbdb;
}

.mobile-only {
  display: none;
}

/* HELP CENTER */
.help-subtitle {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.132px;
  color: #080707;
  padding: 20px 24px;
  margin-bottom: 0;
}

.help-center-card-title .settings__accordian-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.help-center-card-title.card {
  padding: 20px 24px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border: none;
}

.help-accordian-span-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.132px;
  color: #363636;
}

.help-accordian-span-icon {
  font-weight: 600;
  font-size: 22px;
}

.help-center-view-all-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 88px;
}
.help-center-view-all {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #080707;
  margin-bottom: 0;
  margin-right: 1em;
  cursor: pointer;
}

.help-contact-card {
  max-width: 420px;
  border-bottom: 1px solid #c6c6c6;
  margin-bottom: 64px;
}
.help-contact-card .plan-details-main {
  margin-bottom: 24px;
}

/* INSURANCE PLAN */
.settings__page-subtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: #000000;
}

.plan-details-main {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
}

.plan-details-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #080707;
}

.plan-details-items {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #080707;
}

.plan-details-useful-info {
  margin-top: 56px;
}
.plan-details-useful-info li {
  margin-bottom: 1em;
}
@media only screen and (max-width: 768px) {
  .settings__side-bar,
  .mobile-hide {
    display: none;
  }

  .mobile-only {
    display: block;
  }

  .settings__page-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.132px;
    color: #000000;
  }

  .settings__content {
    background: #f2f2f2;
    padding: 0px;
  }

  .settings__home.settings-page h1 {
    margin-bottom: 16px;
  }

  .settings-page {
    padding-top: 98px;
    padding-bottom: 60px;
  }

  .settings___home-insurance-info .body-3 {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .settings__home-card {
    background-color: #ffffff;
    border-bottom: 0.5px solid rgba(135, 135, 135, 0.2);
    /* box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.5); */
    padding: 24px 16px;
    margin-bottom: 20px;
  }

  .settings__home-card-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.132px;
  }

  .settings__profile-completion-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.6px;
  }

  .settings__profile-completion-percentage {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .settings__home-card .settings__my-profile-data {
    display: flex;
    flex-direction: column;
  }

  .settings__home-card .settings__profile-completion {
    order: 1;
  }

  .settings__home-card .settings__bullet-list-container {
    order: 2;
  }

  .settings__my-profile.settings-page .settings__page-section {
    background: #ffffff;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
    padding: 26px 16px;
    margin-bottom: 24px;
  }

  .settings__noneditable-data {
    padding-top: 8px;
    padding-bottom: 36px;
  }

  .settings__my-profile .settings__input {
    margin-bottom: 48px;
  }

  .settings__page-section button {
    margin: auto;
  }

  .settings__my-profile.settings-page
    .settings__page-section.settings__service-preferences {
    padding: 44px 16px;
  }

  .mobile-only .settings__profile-completion-section {
    padding: 24px 16px;
  }

  .settings__my-profile .settings__whats-next {
    margin-top: 36px;
    margin-bottom: 0px;
  }

  .settings__my-profile .settings__page-title {
    margin-bottom: 0px;
    margin-left: 16px;
  }

  .settings__privacy-and-security .settings__back-button p,
  .settings__my-profile .settings__back-button p {
    margin-left: 16px;
  }

  .settings__back-button p.body-2 {
    margin-bottom: 24px;
  }

  .settings__my-profile .settings__whats-next p.body-2 {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.5px;
  }

  .settings__notifications.settings-page {
    background-color: #ffffff;
    padding: 98px 16px 80px 16px;
  }

  .settings__notifications.settings-page .settings__page-section {
    border-bottom: none;
  }

  .settings__notifications.settings-page .settings__page-title {
    margin-bottom: 0px;
  }

  .settings__privacy-and-security.settings-page {
    background-color: #ffffff;
    padding: 98px 0px 80px 0px;
  }

  .settings__privacy-and-security .settings__page-title,
  .settings__edit-password,
  .settings__deletions,
  .plan-details-main,
  .plan-details-items,
  .settings__page-subtitle,
  .plan-details-title,
  .plan-details-useful-info {
    padding: 0px 16px;
  }

  /* HELP CENTER */
  .help-subtitle {
    padding: 16px;
  }
  .help-center-card-title.card {
    padding: 8px 16px;
  }

  .help-accordian-span-title {
    font-size: 16px;
    line-height: 40px;
  }

  .help-accordian-span-title {
    max-width: 295px;
  }
}

.home-container {
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.2),
      rgba(219, 225, 241, 0.2)
    ),
    #ffffff;
  padding: 32px 40px;
}
.grid-container {
  display: grid;
  grid-template-columns: minmax(220px, 1fr) minmax(480px, 2fr);
  grid-gap: 42px;
  gap: 42px;
}

.grid-card {
  background-color: transparent;
  border-radius: 8px;
  width: 589px;
  max-width: 100%;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 32px;
}

.absolute-btn {
  position: absolute;
  right: 5%;
  top: 15%;
}

.homefeed__input-container {
  padding-bottom: 16px;
  padding-inline: 16px;
  background-color: white;
}

.green-fill {
  background: rgba(103, 179, 173, 0.2) !important;
  border: 1.5px solid #018077;
  border-radius: 20px;
}

.homefeed .input-container div input {
  padding-right: 9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.homefeed {
  width: 894px;
  max-width: 100%;
  margin: auto;
}
@media screen and (max-width: 768px) {
  .home-container {
    padding: 0;
  }
  .grid-container {
    grid-template-columns: 1fr;
  }

  .grid-card {
    margin-inline: auto;
  }

  .homefeed .input-container div input {
    padding-right: 8rem;
    text-overflow: initial;
  }
  .absolute-btn {
    bottom: 15%;
    top: auto;
  }
  .homefeed-right-container {
    width: 100vw;
    max-width: 100%;
    margin-top: 49px;
  }

  .mobile-stats-card-container {
    height: 178px;
    position: relative;
    bottom: 2%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
  }
  .mobile-stats-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 16px;
    width: 343px;
    background: #ffffff;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    position: relative;
    bottom: 2%;
  }
}

.home-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  grid-gap: 24px;
  gap: 24px;
  width: 265px;
  max-width: 100%;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 32px;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.07);
}

/* PROFILE COMPLETION CARD */
.home-card__user-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #67b3ad;
  align-items: center;
  justify-content: center;
}

.home-card ul,
.home-card li {
  list-style: none;
}

.home-card ul.settings__bullet-list {
  padding-left: 0;
}

.profile-completion__settings-info {
  width: 233px;
  max-width: 100%;
}

.profile-completion__settings-info .text-description {
  color: #737272;
}

/* VERSATILE LINK CARD */
.versatile-card {
  text-align: center;
}

.versatile-card .title {
  align-self: flex-start;
}

.versatile-card button.button.outline-btn.active {
  border: 1.5px solid #080707;
}

/* COVERAGE CARD */
.coverage-card {
  align-items: flex-start;
}

/* HANDL INFO CARD */
.handl-info-container p {
  margin-bottom: 2px;
}

/* INSURANCE SEARCH CARD */
.insurance-search-card {
  width: 587px;
  max-width: 100%;
  background: #018077;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  color: white;
  padding: 24px 16px;
  grid-gap: 24px;
  gap: 24px;
}

.insurance-search-card p {
  color: white;
}

/* ARTICLE CARD */

.article-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px 19px;
  grid-gap: 16px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  width: 100%;
  margin-bottom: 48px;
}

.article-card-container__img {
  height: 174px;
  width: 555px;
  max-width: 100%;
  object-fit: cover;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 10px;
  margin-bottom: 16px;
}

.article-card-container_author-section {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
}

.article-card .slider-control-bottomcenter li {
  margin-inline: 6.67px;
}

.article-card .slider-control-bottomcenter ul {
  top: 0;
}

.article-card .article-card__see-all-btn {
  margin-top: 42px;
}
/* NEW APPOINTMENT */
.homefeed .new-appointment__paragraph {
  width: 555px;
  max-width: 100%;
  padding-inline: 16px;
}
@media screen and (max-width: 768px) {
  .handl-info-container,
  .article-card__text-container {
    padding-inline: 24px;
  }
  .article-card .slider-container {
    padding-inline: 6px;
  }
}

.facility-estimate__container {
    background-color: #f2f1ef;
}

.facility-estimate__estimate-section-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 48px;
    grid-gap: 40px;
    gap: 40px;
}

.facility-estimate__estimate-callout {
    padding: 16px 16px 24px;
    grid-gap: 8px;
    gap: 8px;
    background-color: #DBE5E5;
    color: #00403C;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);

}
.facility-estimate__estimate-section-emphasized {
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    letter-spacing: 0.15px;
    width: 40%;
    margin-bottom: 0px;
    margin-top: -8px;
}
.facility-estimate__estimate-section {
    background-color: white;
    padding: 24px 32px 0px 16px;
    margin-bottom: 24px;
}

.facility-estimate__facility-details {
    width: 100%;
}

.facility-estimate__payment-breakdown-details.card-body{
    background: transparent;
    border-radius: 0;
    margin-top: 37px;
    padding: 0px;
  }

.facility-estimate__payment-breakdown-section {
    padding: 16px 0px 16px;
  }
  
/* Intersection observer/navigator */
.desktop-navigator {
    display: flex;
    grid-gap: 68px;
    gap: 68px;
    align-items: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #080707;
    padding: 16px 40px;
    background: #EFEFEF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
    margin-bottom: 0px;
    position: fixed;
    width: 100vw;
    max-width: 100%;
    justify-content: space-evenly;
    cursor: pointer;
    z-index: 995;
}

.desktop-navigator-selected {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #080707;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    text-decoration-thickness: 20%;

}
.mobile-navigator {
    position: fixed;
    background: #F7F7F7;
    z-index: 995;
    width: 100vw;
}

.mobile-navigator-dropdown {
    margin-top: 50px;
      
}

.mobile-navigator select {
    border: 0;
    width: 100%;
    padding-inline: 1rem;
    height: 3rem;
    background: #F7F7F7;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    color: #080707;
    height: calc(1.2em+24px);
    border-right: 16px solid transparent;
    


}




.mobile-navigator select option:checked:after {
    content: attr(title);
    background: #666;
    color: #fff;
    position: absolute;
    width: 100%;
    left: 0;
    border: none;
}

.mobile-navigator select:active, select:hover {
    outline: none
  }

  #quality-ratings, #service-details, #cost-and-location, #other-estimates {
    scroll-margin-top: 125px;
  }

.facility-estimate__callout-main-text {
    font-family: 'Crimson Pro';
    font-style: normal;
    font-weight: 200;
    font-size: 36px;
    line-height: 47px;
    letter-spacing: 0.353796px;
    color: #00403C;
    padding-bottom: 24px;
}

.star {
    padding-right: 9px;
}


.tag-container {
    display:flex;
    align-items: center; 
    grid-gap: 12px; 
    gap: 12px; 
    margin-bottom: 17px;

}


.tag-text {
    margin: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: #00403C;
}

.facility-estimate__callout-facility-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.132px;
    color: #00403C;
    padding-top: 16px;
    padding-bottom: 8px;
    margin-bottom: 0;

}
.facility-estimate__callout-facility-address {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.25px;
    margin: 0;
    color: #00403C;
}
.save-for-later-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 64px;
}
.save-for-later-container .save-for-later-btn {
    margin-inline: 0px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #00403C!important;
}

.facility-estimate__estimate-section-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.15px;
    color: #080707;
    margin-bottom: 24px;
}

/* NOTE: come back to fix the margin issue w/ View payment details */
.facility-estimate__payment-accordion-toggle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.15px;
    text-transform: uppercase;
    color: #000000;

}
.ratings-description {
    width: 480px;
    max-width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.132px;
    margin-bottom: 0;
    margin-top: 16px;
    color: #000000;
}

.rating-detail-anchor {
    color: #007bff;
}

.quality-rating-unavailable-text {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #00403C;
}
/* Average Cost Section */
.facility-estimate-page__section {
    padding: 32px 64px 0 64px;
}
.facility-estimate-page__cost-comparison-section {
    background-color: rgb(233, 226, 222);
    padding-top: 32px;
}
    
.facility-estimate__secondary-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    letter-spacing: 1.5px;
    text-transform: uppercase;

    /* Color/4. Text/Black */

    color: #080707;
    margin-bottom: 0;
}
.facility-estimate__text-bold {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.132px;
    color: #080707;
}
.facility-estimate__facility-box p {
    margin: 0;
}
.facility-estimate__facility-box {
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.4);
}
.facility-estimate__facility-detail {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.4px;
    
    /* Color/4. Text/Black */
    
    color: #080707;
    
}

.facility-estimate-page__facility-page-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.132px;
    color: #000000;
}
.costgraph-disclaimer {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.132px;
    
    /* Color/4. Text/Black */
    
    color: #080707;
    padding-left: 16px;
    padding-bottom: 50px;
    max-width: 960px;
    margin: auto;
}

/* OTHER FACILITIES */
.other-facilities-container {
    margin-bottom: 16px;
    position: relative;
    cursor: pointer;
}
.other-facilities-container:last-of-type {
    margin-bottom: 0px;
    padding-bottom: 36px;
}

.other-facilities-container::after {
    content: '';
    width: 100vw;
    max-width: 100%;
    border-bottom: 0.5px solid #D8D8D8;
    height: 0.5px;
    position: absolute;
    bottom: 0;
    left: 0;
}
.cpt-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: #000000;
    
}

.service-name-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.132px;
    
    color: #000000
}

.hospital-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.4px;

    color: #262525;
}

.price-bar-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.15px;
    color: #000000;
    margin-bottom: 24px;
}

/* QUALITY RATINGS SECTION */
.quality-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.15px;

    /* Color/4. Text/Black */

    color: #080707;
}

.text-btn-link, .text-btn-link:hover, .text-btn-link:focus, .text-btn-link:active {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;

    /* Color/4. Text/Black */

    color: #080707;
    display: block;
}
/* GENERAL STYLES */
.width-368 {
    width: 368px;
    max-width: 100%;
}

.width-480 {
    width: 480px;
    max-width: 100%;
}

.width-896 {
    max-width: 896px;
    margin: auto;
}

/* TABLET SPECIFIC STYLES */
@media screen and (max-width:1000px) and (min-width:768px) {
    .facility-estimate__estimate-section,
    .facility-estimate__estimate-callout {
        padding-inline: 64px;
    }
}
/* MOBILE SPECIFIC STYLES */
@media screen and (max-width:768px) {
    .facility-estimate__text-bold {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.132px;

        /* Color/4. Text/Black */

        color: #080707;
    }

    .facility-page__cost-comparison-graph-section .facility-estimate__text-bold {
        width: 100%;
        padding-left: 16px;
    }
    .facility-estimate-page__facility-page-title {
        font-weight: 500;
        font-size: 22px;
        line-height: 33px;
        letter-spacing: 0.15px;
    }

    .other-facilities-container::after {
        width: 100vw;
        max-width: none;
        margin-left: -16px;
    }

    .costgraph-disclaimer {
        padding-bottom: 0px;
    }
    .price-bar-title {
        margin-bottom: 0px;
    }

    .facility-estimate__estimate-section-emphasized {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */

        letter-spacing: 0.15px;

        color: #000000;
    }
    .facility-estimate__estimate-section-container {
        grid-gap: 0px;
        gap: 0px;
        flex-direction: column;
        padding-bottom: 40px;
    }
}
.likertLegend {
  font-size: 48px;
  line-height: 53px;
  font-family: 'Crimson Pro', 'Times New Roman', Times, serif;
  font-weight: 900;
  padding-bottom: 24px;
}

.likertText {
  font-family: 'Poppins';
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  margin-top: 20px;
}

.likertResponse:nth-child(1) .likertIndicator {
  background-image: url("https://img.icons8.com/emoji/48/000000/angry-face-emoji--v2.png");
  width: 48px;
  height: 48px;
  border: none;
  background-color: transparent;
  bottom: -10px;
}

.likertResponse:nth-child(2) .likertIndicator {
  background-image: url("https://img.icons8.com/emoji/48/000000/neutral-face.png");
  width: 48px;
  height: 48px;
  border: none;
  background-color: transparent;
}

.likertResponse:nth-child(3) .likertIndicator {
  background-image: url("https://img.icons8.com/emoji/48/000000/cowboy-hat-face.png");
  width: 48px;
  height: 48px;
  border: none;
  background-color: transparent;
}

.likertResponse > input:checked + .likertIndicator {
  background-color: black;
}

.share-feedback button.button {
  margin-top: 24px;
}

@media only screen and (max-width: 768px) {
  .likertLegend {
    font-size: 30px;
    line-height: 33px;
  }

  .likertResponse {
    min-width: unset;
  }

  .likertBand {
    width: 200px;
  }
}

@media only screen and (max-width: 350px) {
  .likertText {
    font-size: 10px;
    line-height: 16px;
  }
}

.save-estimate-text {
  font-size: 16px;
}

.save-estimate-heading {
  font-size: 60px;
  line-height: 67px;
  font-family: 'Crimson Pro', 'Times New Roman', Times, serif;
  font-weight: 900;
}

.save-your-estimate-modal .send-estimate-form section {
  display: flex;
  justify-content: center;
}

#share-feedback-container {
  display: none;
}

.send-estimate-form button.button {
  margin-top: 24px;
}

@media only screen and (max-width: 768px) {
  .save-estimate-heading {
    font-size: 36px;
  }

  .save-your-estimate-modal p {
    padding-right: 0px;
  }
}

select.form-control {
  border: none;
  font-size: 16px;
  color: black;
  padding:0px;
  font-family: Poppins;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: white;
  /* Hide default select arrow for Firefox */
  -moz-appearance: none;
  /* Hide default select arrow for Chrome */
  -webkit-appearance: none;
}

/* Hide default select arrow for IE10 */
select::-ms-expand {
  display: none;
}

.service-page-select {
  background-color: silver;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.3);
  letter-spacing: 0.5px;
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid black;
  color: black;
}

.quote-options-select.form-control {
  background-color: #D8D8D8;
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
  padding-left: 20px;
  padding-right: 33px;
  border-bottom: 1px solid #979797;
  color: black;
}

.quote-options-select.form-control:valid,
.quote-options-select.form-control:focus {
  background-color: #D8D8D8;
  background-image: none !important;
  border-bottom: 1px solid #979797 !important;
}

.quote-options-select.form-control:invalid {
  background-image: none !important;
}

select:required:invalid {
  color: #979797;
}

.quote-options-select::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #979797;
  opacity: 1; /* Firefox */
}

.quote-options-select:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #979797;
  opacity: 1; /* Firefox */
}

.quote-options-select::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #979797;
  opacity: 1; /* Firefox */
}

.quote-options-select:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #979797;
}

.quote-options-select::-ms-input-placeholder { /* Microsoft Edge */
  color: #979797;
}


#estimate-pdf-container {
  display: none;
  width: 816px;
}

.estimate-pdf .estimate__estimate-callout {
  background-color: white;
  margin-bottom: 0px;
  padding-top: 40px;
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 32px;
}

.estimate-pdf .estimate__callout-main-text,
.estimate-pdf .estimate__estimate-callout-overline-text {
  color: #080707;
  max-width: 100%;
}

.estimate-pdf .estimate__estimate-width.estimate__extra-pdf-padding {
  padding-bottom: 32px;
}

.estimate-pdf .cost-and-coverage .estimate__estimate-section-emphasized,
.estimate-pdf .estimate__estimate-section-emphasized {
  width: 100%;
}

.estimate-pdf .estimate__estimate-section {
  margin-bottom: 0px;
  padding-top: 0px;
  padding-left: 36px;
  padding-right: 36px;
}

.estimate-pdf .estimate__more-facilities {
  background-color: white;
  margin-top: 0px;
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 52px;
}

.estimate-pdf .container-with-top-padding {
  border: none;
}

.estimate-pdf .estimate__estimate-section .estimate__estimate-section-container {
  padding-bottom: 24px;
}

.estimate-pdf .estimate__estimate-section .estimate__estimate-section-container div {
  width: 100%;
}

.estimate-pdf .estimate__estimate-section .estimate__estimate-section-container .estimate__estimate-section-emphasized-container {
  padding-right: 8px;
}

.estimate-pdf .estimate__facility-details {
  width: 60%;
}

.estimate-pdf .estimate__more-facilities-text {
  padding: 0px 16px 0px 0px;
  width: 100%;
  margin-bottom: 0px;
}

.estimate-pdf ul.estimate__facilities-list {
  width: 100%;
  margin-bottom: 0px;
}

.estimate-pdf .estimate__facilities-list li {
  padding: 0px;
}

.estimate-pdf .estimate__facility-name {
  padding-right: 8px;
}

#estimate-pdf-2 {
  padding-top: 36px;
}

/* .estimate-pdf .where-youll-be {
  padding-top: 40px;
} */

.question-section {
  background-color: white;
  padding: 32px 0px;
  margin-bottom: 24px;
}

.question-section__width {
  max-width: 896px;
  margin: auto;
}

.question-section__overline {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.15px;
  margin-bottom: 24px;
}

.question-section__content {
  display: flex;
}

.question-section__title {
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: 0.132px;
  min-width: 350px;
  margin-top: -8px;
  width: 80%;
  padding-right: 50px;
}

.question-section__details {
  width: 100%;
  padding-bottom: 40px;
}

.question-section__question-card {
  margin-bottom: 16px;
  color: #767575;
}

.question-section__question-card.active {
  color: #080707;
}

.question-section__question-card-body.card-body {
  padding: 8px 0px;
}

.question-section__toggle {
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.132px;
}

@media only screen and (max-width: 768px) {
  .question-section__content {
    display: block;
  }

  .question-section {
    padding: 32px 16px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  }

  .question-section__overline {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.15px;
    margin-bottom: 8px;
  }

  .question-section__title {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.132px;
    margin-bottom: 24px;
    padding: 0px;
    width: 100%;

  }

  .question-section__toggle {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.132px;
  }
}

.service-page__insured-price-bars,
.service-page__cash-price-bars {
  position: relative;
  z-index: 0;
}

.service-page__cash-price-bars {
  margin-bottom: 46px;
}

.service-page__bar {
  position: relative;
}

.service-page__bar.max-bar {
  position: absolute;
  top: 0;
  min-width: 150px;
}

.service-page__bar-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}

.service-page__cash-price-bars .service-page__bar-labels {
  -webkit-animation: show-bar-two 1.2s 0.2s forwards;
  animation: show-bar-two 1.2s 0.2s forwards;
}

.service-page__insured-price-bars .service-page__bar-labels {
  -webkit-animation: show-bar-four 1.2s 0.4s forwards;
  animation: show-bar-four 1.2s 0.4s forwards;
}

.service-page__bar-labels p {
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: white;
  margin: 0px;
}

.service-page__min-cash-price .service-page__antimated-bar:after {
  width: 24px;
  background: #A96355;
  position: absolute;
  height: 100%;
  content: '';
  right: -12px;
  top: 0;
  -webkit-transform: skew(35deg);
          transform: skew(35deg);
  z-index: 1;
}

.service-page__min-insured-price .service-page__antimated-bar:after {
  width: 24px;
  background: #42738F;
  position: absolute;
  height: 100%;
  content: ' ';
  right: -12px;
  top: 0;
  -webkit-transform: skew(35deg);
          transform: skew(35deg);
  z-index: 1;
}

.service-page__min-cash-price .service-page__antimated-bar {
  background-color: #A96355;
  z-index: 1;
  position: relative;
  -webkit-animation: show-bar-one 1.2s 0.1s forwards;
  animation: show-bar-one 1.2s 0.1s forwards;
  padding: 6px 16px 6px 8px;
}

.service-page__max-cash-price .service-page__antimated-bar{
  background-color: #AA3D26;
  text-align: right;
  -webkit-animation: show-bar-two 1.2s 0.2s forwards;
  animation: show-bar-two 1.2s 0.2s forwards;
  border-radius: 0px 36px 36px 0px;
  padding: 6px 16px 6px 8px;
}

.service-page__min-insured-price .service-page__antimated-bar{
  background-color: #42738F;
  z-index: 1;
  position: relative;
  -webkit-animation: show-bar-three 1.2s 0.3s forwards;
  animation: show-bar-three 1.2s 0.3s forwards;
  padding: 6px 16px 6px 8px;
}

.service-page__max-insured-price .service-page__antimated-bar{
  background-color: #22536F;
  text-align: right;
  -webkit-animation: show-bar-four 1.2s 0.4s forwards;
  animation: show-bar-four 1.2s 0.4s forwards;
  border-radius: 0px 36px 36px 0px;
  padding: 6px 16px 6px 8px;
}

.pricebars-label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  
  letter-spacing: 1.5px;
  text-transform: uppercase;
  
  /* Color/4. Text/Black */
  
  color: #080707;
  margin-bottom: 4px;
}

/* Bar Graph Horizontal Animations */
@-webkit-keyframes show-bar-one {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes show-bar-one {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes show-bar-two {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes show-bar-two {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes show-bar-three {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes show-bar-three {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes show-bar-four {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes show-bar-four {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.appointment-detail__desktop-card .facility-estimate__estimate-section-container {
    flex-direction: column;
}
.appointment-detail__desktop-card .facility-estimate__estimate-section-emphasized {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.132px;
    color: #080707;
}
.appointment-detail__desktop-card .estimate__estimate-section-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: #000000;
}

.appointment-detail__desktop-card .accordion>.card>.card-header  {
    margin: auto;
    background-color: #F2F1EF;

}

.appts-small .accordion>.card>.card-header  {
    margin: auto;
}

/* DESKTOP ONLY */
@media screen and (min-width:768px) {
    .appointment-detail__desktop-card #payment-breakdown, 
    .appointment-detail__desktop-card .border-0, 
    .appointment-detail__desktop-card .card .estimate__payment-accordion-toggle {
    background-color: #F2F1EF!important;
}
}
.appointment-detail__desktop-section {
    background: #F2F1EF;
}

.appointment-detail__desktop-section .question-section__content {
    display: block;
}

.appointment-detail__desktop-section .question-section__details {
    padding-bottom: 0;
}

.appointment-detail__desktop-card .question-section {
    padding: 0;
    margin-bottom: 32px;
}

.appointment-detail__desktop-section .accordion .card {
    background-color: inherit;
}

.appointment-detail__desktop-section .question-section__details .body-1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: #080707;
}
.cost-page-content {
  height: 100vh;
  background-color: #ffffff;
  padding: 24px 0;
}

.card_detail-name {
  display: flex;
  flex-direction: column;
}

.cost-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 45px;
  gap: 45px;
}

.cost-row {
  display: flex;
  grid-gap: 25px;
  gap: 25px;
}

.cost-container {
  margin: auto;
  width: 876px;
  max-width: 100%;
}

.cost-container form {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;
}

.cost-container input,
.cost-container div,
.cost-container form {
  width: 100%;
}

.cost-btn {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  padding-top: 1rem;
}

div.cost-btn > .search-by-btn {
  justify-self: self-start;
}

.cost-btn > .button {
  position: absolute;
  grid-column: 2;
  justify-self: center;
}

.cost-btn > .dropdown {
  grid-column: 3;
  justify-self: end;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.result-container-cost {
  display: flex;
}

.cost-results__container-tag {
  padding: 4px 16px;
  background-color: #dbe1f1;
  border-radius: 20px;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: #080707;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 3px;
}

.cost-card-result {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  position: relative;
}

.cost-card-result {
  background-color: transparent;
  box-shadow: none;
  color: #000;
}

.result-container-cost {
  display: flex;
  width: 100%;
}

.cost-card-result {
  padding: 30px 30px 25px 40px;
  border-bottom: 0.8px solid rgb(101, 71, 71);
  background-color: white;
}

.page-result {
  padding: 0px 90px 0px 90px;
  background-color: #f1f1f1;
}

.results-bar {
  display: flex;
  background-color: #f1f1f1;
  margin-left: 90px;
  font-size: 22px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 33px;
  font-size: 22px;
  font-family: "Poppins";
  height: 3vh;
}
.npi-error {
  color: #d94411;
  font-weight: 400px;
  margin-top: 8px;
}

.error-message-return {
  display: flex;
  max-width: 70%;
  margin: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  color: black;
  font-family: "Crimson Pro", serif;
  font-weight: 200px;
}

.results-module-section {
  background-color: #f1f1f1;
  align-items: center;
  justify-content: center;
  padding-top: 70px;
  margin-top: 23px;
}

.total-cost-tag {
  display: flex;
  font-weight: 600px;
  font-size: 24px;
  margin-bottom: 0;
  align-items: center;
  justify-content: center;
}

.card-secondary-info {
  color: #262525;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
  max-width: 80%;
}

.cost-results__container-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 72px;
  gap: 72px;
}

.estimate-details-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  flex-direction: column;
}

.estimate-box {
  display: flex;
  background-color: #F2F1EF;
  padding: 32px;
  grid-gap: 32px;
  gap: 32px;
  flex-direction: column;
  margin-bottom: 32px;
  }


  .provider-description-section {
    display: flex;
    width: 754px;
    height: 65px;

  }

  .estimate-details-title {
    display:flex;
    width: 344px;
    height: 32px;
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.15px;
    font-style: "Poppins";
  }

  .doctor-description {
    width: 387px;
    height: 32px;
    top: 35px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.13px;
    max-width: 90%;
  }
  .estimates-titles-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  font-style: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  }

.fee-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 10%;
  font-style: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}


hr.solid {
  border-top: 3px solid #bbb;
}

.back-button-estimateDetails {
  width: 196px;
  height: 24px;
}

.header-estimate-details {
  display: flex;
  width: 890px;
  height: 113px;
grid-gap: 16px;
gap: 16px;
}

.facility-info-estimate-details {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins";
  width: 756px;
}

.provider-specialty {
  width: 325px;
  height: 48px;
  max-width: 320px;
}

.facility-description-section {
  display: flex;
  width: 754px;
  height: 80px;

}

.facility-specialty {
  width: 360px;
  height: 32px;
  max-width: 320px;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.13px;

}

.not-available-cost-tag {
  max-width: 2150px;
  height: 30px;
  font-weight: 600px;
  font-size: 18px;
  margin-bottom: 0;
}
button.filter {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #080707;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 18px;
  padding: 6px 25px;
  border-radius: 25px;
  border: 1px solid black;
  margin: 0px;
}

.all-services-heading .dropdown .dropdown-menu.filter-dropdown {
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.3);
  border: none;
  margin: 5px 0px !important;
  border-radius: 24px;
  background-color: white;
}

.dropdown-item.filter-item:hover {
  background-color: rgba(0,0,0,0.2) !important;
}

.filter.dropdown-toggle::after {
  border: solid #979797;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.currently-selected {
  background-color: rgba(103, 179, 173, 0.2)!important;
  font-family: 'Poppins'!important;
  font-style: normal!important;
  font-weight: 600!important;
  font-size: 18px!important;
  line-height: 27px!important;
  letter-spacing: 0.132px!important;
  color: #018077!important;

}

.fancy-search-sort {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
}
.page-content {
    padding: 0 64px 32px 64px;
}

.fancy-search__split-input {
    margin-bottom: 16px;
    margin-top: 50px;
    width: 50%;
}

.use-my-location-text {
    margin-bottom: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #4C6BBB;
    margin-left: 8px;
}
.relative-container {
    position: relative;
}

.results-container {
    background-color: #f2f1ef;
    min-height: 40vh;
}
.sort-container {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    padding-top: 22px;
    border-top: 1px solid rgba(0, 64, 60, 0.1);
}
.sort-container .dropdown {
    position: static;
}
.sort-filter {

}

.scroll-container {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
    grid-gap: 3%;
    gap: 3%;
    background-color:#F7F7F7;
    padding-top: 32px;
}

.hide-scroll::-webkit-scrollbar {
    background: transparent; /* make scrollbar transparent */
    width: 0px;
}
 :is(.page-content, .cost-page-content) .dropdown .filter-option {
    padding: 4px 12px;
    height: -webkit-max-content;
    height: max-content;
    border: 0.8px solid #C6C6C6;
    border-radius: 20px;
    white-space: nowrap;
    cursor: pointer;
    color: #000000;
}
:is(.page-content, .cost-page-content) .dropdown .dropdown-toggle::after {
    display: none;
}
:is(.page-content, .cost-page-content) .dropdown-item.filter-item:hover {
    /* background-color: rgba(0,0,0,0.2) !important; */
    background: rgba(103, 179, 173, 0.1)!important;
  }
:is(.page-content, .cost-page-content) .dropdown-item.filter-item {
    padding: 19px 0px 19px 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
  }
:is(.page-content, .cost-page-content) .filter-dropdown {
    width: 266px;
}
  
:is(.page-content, .cost-page-content) .dropdown .btn-primary,
:is(.page-content, .cost-page-content) .dropdown .btn-primary:focus,
:is(.page-content, .cost-page-content) .dropdown .btn-primary:active,
:is(.page-content, .cost-page-content) .dropdown .btn-primary:hover,
:is(.page-content, .cost-page-content) .show>.btn-primary.dropdown-toggle:focus,
:is(.page-content, .cost-page-content) .show>.btn-primary.dropdown-toggle

 {
    background-color: transparent;
    border: 0.8px solid #C6C6C6;
    box-shadow: none;
    color: #000000;

}

:is(.page-content, .cost-page-content) .dropdown .filter-option-active
 {
    padding: 4px 12px;
    height: -webkit-max-content;
    height: max-content;
    border-radius: 20px;
    white-space: nowrap;
    cursor: pointer;
    background: rgba(103, 179, 173, 0.2)!important;
    color: #00403C!important;
    border: 1.8px solid #018077!important;


}
:is(.page-content, .cost-page-content) .dropdown .btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
}

:is(.page-content, .cost-page-content) .dropdown .btn-primary:not(:disabled):not(.disabled):active {
    background-color: transparent;
    border: 0.8px solid #C6C6C6;
    box-shadow: none;
    color: #000000;
}


.filter-option {
    padding: 4px 12px;
    height: -webkit-max-content;
    height: max-content;
    border: 0.8px solid #C6C6C6;
    border-radius: 20px;
    white-space: nowrap;
    cursor: pointer;
    color: #000000;
    margin-right: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.5px;

}

.filter-option-active {
    padding: 4px 12px;
    height: -webkit-max-content;
    height: max-content;
    border-radius: 20px;
    white-space: nowrap;
    cursor: pointer;
    background: rgba(103, 179, 173, 0.2);
    color: #00403C;
    border: 1.8px solid #018077;
    margin-right: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.5px;

}

.filter-option-invalid {
    color: #868686;
    cursor: none;
    padding: 4px 12px;
    height: -webkit-max-content;
    height: max-content;
    border: 0.8px solid #C6C6C6;
    border-radius: 20px;
    white-space: nowrap;
    margin-right: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.5px;

}
/* Service Not Found  */
.service-not-found-text {
    font-family: 'Crimson Pro';
    font-style: normal;
    font-weight: 200;
    font-size: 60px;
    line-height: 67px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.353796px;
    padding-top: 113px;
    padding-bottom: 88px;
    max-width: 896px;
    margin: auto;
}

.service-not-found-btn {
    display: block;
    margin: auto;
    cursor: pointer;
}

.service-not-found-footer {
    width: 98.8vw;
    margin-left: -64px;
    margin-bottom: -120px;
    position: relative;
    z-index: 1;
    margin-top: 250px;
}

.overlapping-footer {
    z-index: 995;
    position: relative;
}

.sticky-footer {
    position:absolute;
    bottom: 0;
    width: 100%;
}
.results__container-tag {
    padding: 4px 16px;
    background-color: #DBE1F1;
    border-radius: 20px;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #080707;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-right: 19.88px;   
}

.results-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: #000000;
    padding-left: 64px;
    padding-top: 32px;
}
/* CARD STYLES */
.savings-card {
    padding: 16px 32px;
    margin-bottom: 24px;
}
.card-result {
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    position: relative;
}

.card-result::after {
    content: '';
    width: calc(100% + 64px);
    border-bottom: 0.5px solid #D8D8D8;
    height: 0.5px;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: -32px;
    display: inline-block;
}
.card-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 24px;
}

.card__detail-distance {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    color: #000000;

}

.card__detail-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #080707;
    max-width: 19em;
}

.card__detail-tag {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.4px;
    color: #262525;
}

/* SEE MORE ESTIMATES */

.showing-count-text {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5px;
}

.see-more-estimates {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #080707;
    border: none;
    margin: auto;
    display: block;
    background-color: #f2f1ef;
}

.see-more-progress .progress {
    background-color: #C4C4C4;
    width: 343px;
    max-width: 100%;
    margin: auto;
    height: 4px;
    margin-bottom: 30px;
}
.see-more-progress .progress-bar {
    background-color: #080707
    
}

/* MOBILE STYLES */
.absolute-container {
    padding: 16px;
    display: flex;
    flex-direction: column;
    background: #F2F1EF;
    min-height: 100vh;
}
.absolute-container .mobile-search__close {
    margin: 24px 0;
    align-self: flex-end;
   
}
.mobile-search__info-panel-container .mobile-search__close {
    margin: 24px 0 32px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400!important;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 160% */

    letter-spacing: 0.9px;
    text-transform: uppercase;

    /* Color/4. Text/Black */

    color: #080707;
   
}

.facility-estimate__estimate-callout .mobile-search__close {
    margin: 24px 0 48px 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400!important;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 160% */

    letter-spacing: 0.9px;
    text-transform: uppercase;

    /* Color/4. Text/Black */

    color: #080707;
    margin-top: 40px;
    padding-top: 40px;
   
}

.absolute-container .mobile__search-results {
    margin: 235px auto 100px auto;
}

.mobile-search__info-panel-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 16px 0px;
    grid-gap: 24px;
    gap: 24px;
    
    background: #DBE5E5;
}

.mobile-search__info-panel-estimates {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #000000;
}

.mobile-search__info-panel-servicename {
    font-family: 'Crimson Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 31px;
    display: flex;
    align-items: center;
    letter-spacing: 0.353796px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #00403C;
}
.sort-modal  {
    max-width: 100%!important;
    position: absolute!important;
    margin-left: -24px!important;
    bottom: 0;
    width: 100vw!important;
    min-height: -webkit-fit-content!important;
    min-height: -moz-fit-content!important;
    min-height: fit-content!important;
    margin-bottom: 0!important;
}
.mobile-sort-container {
    display: flex;
    border:0;
    flex-direction: column;
    justify-content: center;
    background-color: #FFFFFF;
    border-radius: 20px 20px 0px 0px;

    position: absolute;
    bottom: 0;
    width: 100vw;
    max-width: 100%;
    margin-left: -16px;

    -webkit-backdrop-filter: opacity(20%);

            backdrop-filter: opacity(20%);
}

.mobile-sort-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    padding-left: 16px;
    padding-top: 22px;
    margin-bottom: 27px;
}

.mobile-sort-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.132px;
    color: #363636;
}

.mobile-sort-options {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;
    padding-inline: 16px;
    margin-bottom: 37px;
}

.mobile-sort-container .sort-btn {
    margin-bottom: 40px;
}

.mobile-sort-radio:checked {
    accent-color: #000000;
}
.mobile-sort-options input[type=radio] {
    -webkit-transform: scale(1.7);
            transform: scale(1.7);
}

.request-a-service {
    margin-bottom: 16px;
}

.request-a-service-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    display: flex;
    align-items: center;
    letter-spacing: 0.132px;
    color: #000000;
}
.request-service-error-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 160% */

    display: flex;
    align-items: center;
    letter-spacing: 0.5px;

    /* Error */

    color: #D94411;

    
}


.modal-body .request-a-service-btn {
    margin-top: 52px;
    margin-bottom: 44px;
}
@media screen and (max-width:768px) {
    .savings-card {
        padding: 16px;
        margin-bottom: 8px;
        padding-bottom:0px;
    }

    .page-content {
        padding: 0px;
    }

    .card-result::after {
        width: calc(100% + 32px);
        margin-left: -16px;
    }
    .card-title {
        font-size: 14px;
        line-height: 21px;
    }

    .card__detail-distance {
        font-size: 12px;
        line-height: 18px;
    }

    .card__detail-name {
        font-size: 18px;
        line-height: 24px;
    }

    .card__detail-tag {
        font-size: 15px;
        line-height: 22px;
    }

    .results-title {
        padding: 16px;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        background: white;
        margin-bottom: 0px;
        border: 1px solid rgba(135, 135, 135, 0.2);
      
    }
    .service-not-found-text {
        font-size: 36px;
        line-height: 40px;
        letter-spacing: 0.353796px;
    }
    .service-not-found-footer {
        width: 100vw;
        margin-left: -16px;
        position: fixed;
        bottom: -20px;
        z-index: 1;
        margin-top: 0px;
        margin-bottom: 0px;
    
    }
    .filter-option-invalid, .filter-option, .filter-option-active {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        /* identical to box height */

        letter-spacing: 1.25px;
    } 
    .facility-estimate__estimate-callout .mobile-search__close {
        margin-top: 0px;
        padding-top: 0px
    }
    .results-container {
        min-height: 50vh;
    }
}

.toast-notification {
  position: fixed;
  width: 300px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px 20px;
  background-color: white;
  z-index: 99999;
  bottom: 10px;
  right: 10px;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 30%);
  border-radius: 24px;
  display: flex;
  align-items: center;
}

.hide {
  display: none;
}

