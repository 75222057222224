.facility-estimate__container {
    background-color: #f2f1ef;
}

.facility-estimate__estimate-section-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 48px;
    gap: 40px;
}

.facility-estimate__estimate-callout {
    padding: 16px 16px 24px;
    gap: 8px;
    background-color: #DBE5E5;
    color: #00403C;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);

}
.facility-estimate__estimate-section-emphasized {
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    letter-spacing: 0.15px;
    width: 40%;
    margin-bottom: 0px;
    margin-top: -8px;
}
.facility-estimate__estimate-section {
    background-color: white;
    padding: 24px 32px 0px 16px;
    margin-bottom: 24px;
}

.facility-estimate__facility-details {
    width: 100%;
}

.facility-estimate__payment-breakdown-details.card-body{
    background: transparent;
    border-radius: 0;
    margin-top: 37px;
    padding: 0px;
  }

.facility-estimate__payment-breakdown-section {
    padding: 16px 0px 16px;
  }
  
/* Intersection observer/navigator */
.desktop-navigator {
    display: flex;
    gap: 68px;
    align-items: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #080707;
    padding: 16px 40px;
    background: #EFEFEF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
    margin-bottom: 0px;
    position: fixed;
    width: 100vw;
    max-width: 100%;
    justify-content: space-evenly;
    cursor: pointer;
    z-index: 995;
}

.desktop-navigator-selected {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #080707;
    text-decoration-line: underline;
    text-decoration-thickness: 20%;

}
.mobile-navigator {
    position: fixed;
    background: #F7F7F7;
    z-index: 995;
    width: 100vw;
}

.mobile-navigator-dropdown {
    margin-top: 50px;
      
}

.mobile-navigator select {
    border: 0;
    width: 100%;
    padding-inline: 1rem;
    height: 3rem;
    background: #F7F7F7;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    color: #080707;
    height: calc(1.2em+24px);
    border-right: 16px solid transparent;
    


}




.mobile-navigator select option:checked:after {
    content: attr(title);
    background: #666;
    color: #fff;
    position: absolute;
    width: 100%;
    left: 0;
    border: none;
}

.mobile-navigator select:active, select:hover {
    outline: none
  }

  #quality-ratings, #service-details, #cost-and-location, #other-estimates {
    scroll-margin-top: 125px;
  }

.facility-estimate__callout-main-text {
    font-family: 'Crimson Pro';
    font-style: normal;
    font-weight: 200;
    font-size: 36px;
    line-height: 47px;
    letter-spacing: 0.353796px;
    color: #00403C;
    padding-bottom: 24px;
}

.star {
    padding-right: 9px;
}


.tag-container {
    display:flex;
    align-items: center; 
    gap: 12px; 
    margin-bottom: 17px;

}


.tag-text {
    margin: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: #00403C;
}

.facility-estimate__callout-facility-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.132px;
    color: #00403C;
    padding-top: 16px;
    padding-bottom: 8px;
    margin-bottom: 0;

}
.facility-estimate__callout-facility-address {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.25px;
    margin: 0;
    color: #00403C;
}
.save-for-later-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 64px;
}
.save-for-later-container .save-for-later-btn {
    margin-inline: 0px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #00403C!important;
}

.facility-estimate__estimate-section-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.15px;
    color: #080707;
    margin-bottom: 24px;
}

/* NOTE: come back to fix the margin issue w/ View payment details */
.facility-estimate__payment-accordion-toggle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.15px;
    text-transform: uppercase;
    color: #000000;

}
.ratings-description {
    width: 480px;
    max-width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.132px;
    margin-bottom: 0;
    margin-top: 16px;
    color: #000000;
}

.rating-detail-anchor {
    color: #007bff;
}

.quality-rating-unavailable-text {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #00403C;
}
/* Average Cost Section */
.facility-estimate-page__section {
    padding: 32px 64px 0 64px;
}
.facility-estimate-page__cost-comparison-section {
    background-color: rgb(233, 226, 222);
    padding-top: 32px;
}
    
.facility-estimate__secondary-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    letter-spacing: 1.5px;
    text-transform: uppercase;

    /* Color/4. Text/Black */

    color: #080707;
    margin-bottom: 0;
}
.facility-estimate__text-bold {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.132px;
    color: #080707;
}
.facility-estimate__facility-box p {
    margin: 0;
}
.facility-estimate__facility-box {
    gap: 20px;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.4);
}
.facility-estimate__facility-detail {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.4px;
    
    /* Color/4. Text/Black */
    
    color: #080707;
    
}

.facility-estimate-page__facility-page-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.132px;
    color: #000000;
}
.costgraph-disclaimer {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.132px;
    
    /* Color/4. Text/Black */
    
    color: #080707;
    padding-left: 16px;
    padding-bottom: 50px;
    max-width: 960px;
    margin: auto;
}

/* OTHER FACILITIES */
.other-facilities-container {
    margin-bottom: 16px;
    position: relative;
    cursor: pointer;
}
.other-facilities-container:last-of-type {
    margin-bottom: 0px;
    padding-bottom: 36px;
}

.other-facilities-container::after {
    content: '';
    width: 100vw;
    max-width: 100%;
    border-bottom: 0.5px solid #D8D8D8;
    height: 0.5px;
    position: absolute;
    bottom: 0;
    left: 0;
}
.cpt-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: #000000;
    
}

.service-name-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.132px;
    
    color: #000000
}

.hospital-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.4px;

    color: #262525;
}

.price-bar-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.15px;
    color: #000000;
    margin-bottom: 24px;
}

/* QUALITY RATINGS SECTION */
.quality-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.15px;

    /* Color/4. Text/Black */

    color: #080707;
}

.text-btn-link, .text-btn-link:hover, .text-btn-link:focus, .text-btn-link:active {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;

    /* Color/4. Text/Black */

    color: #080707;
    display: block;
}
/* GENERAL STYLES */
.width-368 {
    width: 368px;
    max-width: 100%;
}

.width-480 {
    width: 480px;
    max-width: 100%;
}

.width-896 {
    max-width: 896px;
    margin: auto;
}

/* TABLET SPECIFIC STYLES */
@media screen and (max-width:1000px) and (min-width:768px) {
    .facility-estimate__estimate-section,
    .facility-estimate__estimate-callout {
        padding-inline: 64px;
    }
}
/* MOBILE SPECIFIC STYLES */
@media screen and (max-width:768px) {
    .facility-estimate__text-bold {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.132px;

        /* Color/4. Text/Black */

        color: #080707;
    }

    .facility-page__cost-comparison-graph-section .facility-estimate__text-bold {
        width: 100%;
        padding-left: 16px;
    }
    .facility-estimate-page__facility-page-title {
        font-weight: 500;
        font-size: 22px;
        line-height: 33px;
        letter-spacing: 0.15px;
    }

    .other-facilities-container::after {
        width: 100vw;
        max-width: none;
        margin-left: -16px;
    }

    .costgraph-disclaimer {
        padding-bottom: 0px;
    }
    .price-bar-title {
        margin-bottom: 0px;
    }

    .facility-estimate__estimate-section-emphasized {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */

        letter-spacing: 0.15px;

        color: #000000;
    }
    .facility-estimate__estimate-section-container {
        gap: 0px;
        flex-direction: column;
        padding-bottom: 40px;
    }
}