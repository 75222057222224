.settings__side-bar {
  width: 328px;
  background: #e5e5e5;
  padding: 64px 32px;
  z-index: 0;
}

.settings__content {
  padding-left: 32px;
  padding-right: 32px;
}

.settings__page-title {
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.15px;
  margin-bottom: 24px;
}

.settings__home.settings-page h1 {
  font-weight: 200;
  font-size: 45.94px;
  line-height: 51px;
  letter-spacing: 0.353796px;
  margin-bottom: 24px;
}

.settings__side-bar a,
.settings__page-content a,
.plan-details-useful-info a {
  color: black;
  text-decoration: underline;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}

.settings-page .body-1,
.settings-page .body-2,
.settings-page .body-3 {
  margin-bottom: 8px;
}

.settings__subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
  margin-bottom: 8px;
}

.settings__content {
  max-width: 650px;
}

.settings__page-section button {
  margin: 0;
}

/* COMPLETION PROGRESS */

.settings__profile-completion-descripton {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.settings__profile-completion-text {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.home__profile-completion-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.6px;
  color: #080707;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.settings__profile-completion-percentage {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
}

.home__profile-completion-percentage {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #080707;
  margin-bottom: 8px;
}

.settings__profile-completion-bar {
  background-color: #c4c4c4;
  width: 100%;
  height: 10px;
  display: block;
  border-radius: 8px;
}

.settings__profile-completion-bar-filled {
  height: 100%;
  background-color: #4c6bbb;
  display: block;
  border-radius: 8px;
}

/* WHAT'S NEXT */

.settings__whats-next {
  margin-top: 24px;
}

.settings__whats-next p {
  margin-bottom: 8px;
}

.settings__whats-next li {
  margin-bottom: 12px;
}

/* SIDEBAR PRIVACY NOTICE */

.settings__privacy-notice {
  margin-top: 80px;
}

.settings__privacy-notice p.settings__privacy-notice-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 16px;
}

.settings__privacy-notice p {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.15px;
  margin-bottom: 8px;
}

/* BULLET LIST */

ul.settings__bullet-list {
  list-style-type: disc;
  padding-left: 1em;
}

ul.settings__bullet-list p {
  display: inline-block;
  position: relative;
  left: -4px;
}

/* HOME */

.settings___home-insurance-info {
  margin-bottom: 32px;
}

.settings__home-see-more-btn {
  margin-top: 40px;
}
.settings__home-card-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.132px;
  margin-bottom: 24px;
}

.settings__home-card {
  border-bottom: 0.5px solid rgba(135, 135, 135, 0.2);
  /* box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.5); */
  padding: 24px;
  cursor: pointer;
}

.settings__home-info {
  padding: 0px 24px;
}

.settings__home.settings-page .settings__profile-completion {
  margin-bottom: 32px;
  margin-top: 32px;
}

/* MY PROFILE */

.settings__my-profile .settings__page-title {
  margin-bottom: 30px;
}

.settings__my-profile .settings__whats-next {
  margin-bottom: 80px;
}

.settings__my-profile .settings__page-section:not(:last-child) {
  margin-bottom: 104px;
}

.settings__noneditable-data {
  padding-top: 8px;
  padding-bottom: 40px;
}

.settings__my-profile .settings__description {
  margin-bottom: 32px;
}

.settings__my-profile .settings__input {
  margin-bottom: 60px;
}

.settings__my-profile .settings__input .read-only {
  color: #363636;
}

.settings__service-preference-section {
  margin-bottom: 20px;
}

.settings__service-preference-section p {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 12px;
}

.settings__service-preference-section b {
  font-weight: 600;
}

/* NOTIFICATIONS */

.settings__notifications .settings__page-section {
  padding-top: 36px;
  padding-bottom: 32px;
}

/* PRIVACY */

.settings__deletions {
  margin-bottom: 40px;
}

.settings__deletion-section {
  margin-top: 64px;
}

.settings__privacy-card-title.card {
  padding: 20px 24px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1) !important;
}

.settings__privacy-card-title .settings__accordian-toggle {
  display: flex;
  justify-content: space-between;
}

.settings__notifications .settings__page-section:not(:last-child) {
  border-bottom: 1px solid #c6c6c6;
}

.settings__edit-password .settings__input label {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
}

.settings__edit-password .settings__input input {
  padding-top: 30px;
}

.settings__edit-password .settings__input .input-icon.input-action {
  top: unset;
  bottom: 8px;
}

.settings__edit-password .settings__input {
  margin-bottom: 48px;
}

.settings__edit-password .settings__input .read-only {
  color: #080707;
}

.settings__edit-password .settings__input.password-confirmation .read-only {
  color: #dbdbdb;
}

.mobile-only {
  display: none;
}

/* HELP CENTER */
.help-subtitle {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.132px;
  color: #080707;
  padding: 20px 24px;
  margin-bottom: 0;
}

.help-center-card-title .settings__accordian-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.help-center-card-title.card {
  padding: 20px 24px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border: none;
}

.help-accordian-span-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.132px;
  color: #363636;
}

.help-accordian-span-icon {
  font-weight: 600;
  font-size: 22px;
}

.help-center-view-all-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 88px;
}
.help-center-view-all {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #080707;
  margin-bottom: 0;
  margin-right: 1em;
  cursor: pointer;
}

.help-contact-card {
  max-width: 420px;
  border-bottom: 1px solid #c6c6c6;
  margin-bottom: 64px;
}
.help-contact-card .plan-details-main {
  margin-bottom: 24px;
}

/* INSURANCE PLAN */
.settings__page-subtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: #000000;
}

.plan-details-main {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
}

.plan-details-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #080707;
}

.plan-details-items {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #080707;
}

.plan-details-useful-info {
  margin-top: 56px;
}
.plan-details-useful-info li {
  margin-bottom: 1em;
}
@media only screen and (max-width: 768px) {
  .settings__side-bar,
  .mobile-hide {
    display: none;
  }

  .mobile-only {
    display: block;
  }

  .settings__page-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.132px;
    color: #000000;
  }

  .settings__content {
    background: #f2f2f2;
    padding: 0px;
  }

  .settings__home.settings-page h1 {
    margin-bottom: 16px;
  }

  .settings-page {
    padding-top: 98px;
    padding-bottom: 60px;
  }

  .settings___home-insurance-info .body-3 {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .settings__home-card {
    background-color: #ffffff;
    border-bottom: 0.5px solid rgba(135, 135, 135, 0.2);
    /* box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.5); */
    padding: 24px 16px;
    margin-bottom: 20px;
  }

  .settings__home-card-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.132px;
  }

  .settings__profile-completion-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.6px;
  }

  .settings__profile-completion-percentage {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .settings__home-card .settings__my-profile-data {
    display: flex;
    flex-direction: column;
  }

  .settings__home-card .settings__profile-completion {
    order: 1;
  }

  .settings__home-card .settings__bullet-list-container {
    order: 2;
  }

  .settings__my-profile.settings-page .settings__page-section {
    background: #ffffff;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
    padding: 26px 16px;
    margin-bottom: 24px;
  }

  .settings__noneditable-data {
    padding-top: 8px;
    padding-bottom: 36px;
  }

  .settings__my-profile .settings__input {
    margin-bottom: 48px;
  }

  .settings__page-section button {
    margin: auto;
  }

  .settings__my-profile.settings-page
    .settings__page-section.settings__service-preferences {
    padding: 44px 16px;
  }

  .mobile-only .settings__profile-completion-section {
    padding: 24px 16px;
  }

  .settings__my-profile .settings__whats-next {
    margin-top: 36px;
    margin-bottom: 0px;
  }

  .settings__my-profile .settings__page-title {
    margin-bottom: 0px;
    margin-left: 16px;
  }

  .settings__privacy-and-security .settings__back-button p,
  .settings__my-profile .settings__back-button p {
    margin-left: 16px;
  }

  .settings__back-button p.body-2 {
    margin-bottom: 24px;
  }

  .settings__my-profile .settings__whats-next p.body-2 {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.5px;
  }

  .settings__notifications.settings-page {
    background-color: #ffffff;
    padding: 98px 16px 80px 16px;
  }

  .settings__notifications.settings-page .settings__page-section {
    border-bottom: none;
  }

  .settings__notifications.settings-page .settings__page-title {
    margin-bottom: 0px;
  }

  .settings__privacy-and-security.settings-page {
    background-color: #ffffff;
    padding: 98px 0px 80px 0px;
  }

  .settings__privacy-and-security .settings__page-title,
  .settings__edit-password,
  .settings__deletions,
  .plan-details-main,
  .plan-details-items,
  .settings__page-subtitle,
  .plan-details-title,
  .plan-details-useful-info {
    padding: 0px 16px;
  }

  /* HELP CENTER */
  .help-subtitle {
    padding: 16px;
  }
  .help-center-card-title.card {
    padding: 8px 16px;
  }

  .help-accordian-span-title {
    font-size: 16px;
    line-height: 40px;
  }

  .help-accordian-span-title {
    max-width: 295px;
  }
}
