.toast-notification {
  position: fixed;
  width: 300px;
  height: fit-content;
  padding: 10px 20px;
  background-color: white;
  z-index: 99999;
  bottom: 10px;
  right: 10px;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 30%);
  border-radius: 24px;
  display: flex;
  align-items: center;
}

.hide {
  display: none;
}
