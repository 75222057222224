.service__list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 24px;
  border-bottom: 0.5px solid #D8D8D8;
  background-color: white;
}

.service__list-item.transparent-bg {
  background-color: transparent;
}

.service__list-item.highlighted {
  background-color: #EFEFEF;
}

.service__list-item-overline {
  font-family: Poppins;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  margin: 0px;
}

.service__list-item-centerline {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0.132px;
  margin-top: 4px;
  margin-bottom: 0px;
}

.service__list-item-underline {
  font-family: Poppins;
  font-size: 15px;
  line-height: 22px; 
  letter-spacing: 0.4px;
  margin-top: 4px;
  margin-bottom: 0px;
}

.service__arrow {
  width: 6px;
  height: 100%;
  border: solid #080707;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-left: 32px;
}

@media only screen and (max-width: 768px) {
  .service__list-item {
    padding: 12px 0px;
  }

  .padding-adjustment {
    padding: 16px;
  }

  .service__list-item-overline { 
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
  }

  .service__list-item-centerline {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.132px;
  }

  .service__list-item-underline {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.4px;
  }
}
