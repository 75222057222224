.FAQ__accordian-icon {
    font-size: 25px;
    font-weight: 500;
  }
  .FAQ__accordian-footer, .FAQ__accordian-footer .accordion, .FAQ__accordian-footer .card {
    background-color: #EEEFF2;
    ;
  
  } 
  .FAQ__accordian-footer p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    margin-inline: 32px;
    padding-top: 32px;
    margin-bottom: 12px;
  }
  
  .FAQ__accordian-card-title.card {
    font-family: 'Poppins';
    padding: 20px 32px;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1) !important;
  }
  
  .FAQ__accordian-card-title .FAQ__accordian-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  