.handl-logo {
  margin: 0px;
  font-weight: 900;
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav-link {
  margin-left: 10px;
  margin-bottom: 0px;
  cursor: pointer;
  text-decoration: none;
}

.dropdown-item:active {
  background-color: transparent !important;
}

a {
  text-decoration: none;
}

a::hover {
  text-decoration: none;
}

.nav-link::hover {
  text-decoration: none;
}

.nav-link-text {
  margin-bottom: 0px;
}

.navbar {
  max-width: 100vw;
}

.mobile-header.bg-white {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
}

/* header for a different color  */
.mobile-header.bg-blue {
  background-color: #b7c4e4;
}
.header {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
}

.white-arrow {
  border-color: white !important;
}

.back {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
}

.quote-navigation {
  padding: 80px 40px 40px 40px;
  width: 100%;
}

.back-text {
  padding-left: 24px;
  margin-bottom: 0px;
  margin-top: -4px;
}

#resources-dropdown-toggle.btn-primary {
  color: black;
  font-family: "Poppins";
}

#mobile-dropdown-toggle.dropdown-toggle::after,
#resources-dropdown-toggle.dropdown-toggle::after {
  display: none !important;
}
#mobile-dropdown-toggle.blue,
#mobile-dropdown-toggle.blue:focus,
#mobile-dropdown-toggle.blue:active,
#resources-dropdown-toggle.blue,
#resources-dropdown-toggle.blue:focus,
#resources-dropdown-toggle.blue:active {
  background-color: #b7c4e4;
  border-color: #b7c4e4;
  box-shadow: none;
}

#mobile-dropdown-toggle.white,
#mobile-dropdown-toggle.white:focus,
#mobile-dropdown-toggle.white:active,
#resources-dropdown-toggle.white,
#resources-dropdown-toggle.white:focus,
#resources-dropdown-toggle.white:active {
  background-color: white;
  border-color: white;
  box-shadow: none;
}

#mobile-dropdown-toggle.dark,
#mobile-dropdown-toggle.dark:focus,
#mobile-dropdown-toggle.dark:active {
  background-color: #333333;
  border-color: #333333;
  box-shadow: none;
}

#mobile-dropdown-toggle.black,
#mobile-dropdown-toggle.black:focus,
#mobile-dropdown-toggle.black:active {
  background-color: #080707;
  border-color: #080707;
  box-shadow: none;
}

.mobile-header .dropdown .dropdown-menu {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  right: 0px;
  top: 50px;
  left: unset;
}

.mobile-header.bg-black {
  background-color: #080707;
}

a.dropdown-item a {
  color: black;
}

.handl-logo.dark,
.handl-logo.black {
  color: white;
}

.back-arrow.quote-navigation-arrow {
  position: absolute;
  left: 40px;
  top: 82px;
}

.dark-background {
  background-color: #333333 !important;
}

/* USER NAVIGATION */

.navigation-bar {
  position: sticky;
  top: 0;
  background-color: #dbe1f1;
  display: flex;
  align-items: center;
  height: 80px;
  width: 100%;
  padding: 24px 32px;
  z-index: 9999;
}

.navigation-bar__page-links {
  display: flex;
}

.navigation-bar__user-actions {
  display: flex;
  margin-left: auto;
}

.navigation-bar__link {
  color: #080707;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.132px;
  padding: 0px 20px;
}

.navigation-bar__page-links button.navigation-bar__dropdown {
  background-color: transparent;
  color: #080707;
  border: none;
  box-shadow: none;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.132px;
  padding: 0px 20px;
  display: block;
}

button.navigation-bar__dropdown::after {
  display: none !important;
}

button.navigation-bar__dropdown:focus,
button.navigation-bar__dropdown:active,
button.navigation-bar__dropdown:hover,
.navigation-bar__link:hover {
  background-color: transparent;
  color: #080707;
  text-decoration: none;
}

.navigation-bar__user-actions button {
  margin-left: 32px;
}

/* SERVICE SEARCH STYLING

.header-input {
  width: 300px;
  font-family: Poppins;
}

label.service-search-input {
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.3);
  border-radius: 50px;
  height: 51px;
  padding: 5px 25px 0px 25px;
  letter-spacing: 0.5px;
  font-weight: bold;
  margin: 0px 0px;
  min-width: 300px;
}

.desktop-search-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

::placeholder { Chrome, Firefox, Opera, Safari 10.1+
  font-family: Poppins;
  color:black;
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
  opacity: 1; Firefox
}

:-ms-input-placeholder { Internet Explorer 10-11
  font-family: Poppins;
  color:black;
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
}

::-ms-input-placeholder { Microsoft Edge 
  color:black;
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
} */

/* UPDATED MOBILE NAV */

/* hamburger */
.nav-toggle {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 22px;
}

.nav-toggle .bar {
  height: 3px;
  width: 100%;
  background-color: black;
  transition: all 100ms ease-in-out;
}
.x:nth-of-type(1) {
  transition: all 100ms ease-in-out;
  transform: rotate(45deg);
  transform-origin: top left;
  width: 25px;
}

.x:nth-of-type(2) {
  transition: all 100ms ease-in-out;
  transform-origin: center;
  width: 0;
}

.x:nth-of-type(3) {
  transition: all 100ms ease-in-out;
  transform: rotate(-45deg) translate(-3px, 3px);
  transform-origin: bottom left;
  width: 25px;
}

.updated-mobile-nav-container {
  background-color: #dbe1f1;
  min-height: -webkit-fill-available;
  width: 100vw;
  padding-top: 64px;
  padding-inline: 24px;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 999;
  transform: translateY(-100%);
  transition: transform 250ms ease-out;
  overflow: hidden;
}

.updated-mobile-nav-container__active {
  transform: translateY(0%);
}

.updated-mobile-nav-container__menu-options {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #0a2530;
  margin-bottom: 0;
}

.updated-mobile-nav-container ul {
  padding-top: 10px;
  padding-left: 12px;
  visibility: hidden;
}
.updated-mobile-nav__li-options {
  margin-bottom: 16px;
}
.updated-mobile-nav__li-options,
.navigation-bar__user-actions .navigation-bar__user-actions-btn {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #080707;
}
.updated-mobile-nav-container__menu-options:hover {
  color: #4c6bbb;
}

.updated-mobile-nav-container__help-section {
  padding-top: 40px;
}
.updated-mobile-nav-container__help-section p:nth-of-type(1) {
  margin-bottom: 32px;
}
.updated-mobile-nav-container__handl-logo {
  position: absolute;
  bottom: 0;
  right: 0;
}

.find-an-estimate-container {
  padding-top: 42px;
  padding-bottom: 42px;
  gap: 22px;
}

.line {
  width: 327px;
  height: 0px;

  /* Blue/Dark */

  border: 1px solid #4c6bbb;
}
/* DESKTOP NAVIGATION */
.navigation-bar .dropdown .btn:focus {
  box-shadow: none !important;
}
.navigation-bar .userActions-navigation__dropdown-open {
  color: #4c6bbb;
  text-decoration-line: underline;
  text-decoration-color: #4c6bbb;
  text-decoration-thickness: 4px;
}

.navigation-bar .userActions-navigation__dropdown-menu {
  background: #dbe1f1;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 0px;
  top: 24px !important;
  left: 10px !important;
  border: none;
  min-width: 5em;
}

.navigation-bar .userActions-navigation__dropdown-menu .dropdown-item,
.userActions-navigation-bar__items {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #080707;
}

.navigation-bar .userActions-navigation__dropdown-menu .dropdown-item:active,
.navigation-bar .userActions-navigation__dropdown-menu .dropdown-item:hover {
  color: #080707;
  background-color: transparent;
}

.navigation-bar .userActions-navigation__dropdown-menu .dropdown-item:hover {
  text-decoration-line: underline;
  text-decoration-color: #080707;
}

.userActions-navigation__dropdown-user-information {
  display: flex;
  flex-direction: column;
}

.navigation-bar .userActions-navigation__dropdown-user-information-menu {
  background-color: #ffffff;
  padding: 24px 16px 40px;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.15);
  border: none;
  top: 10px !important;
}

.userActions-navigation__dropdown-user-information-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.25px;
  color: #080707;
  margin-bottom: 8px;
}

.userActions-navigation__dropdown-user-information a:hover {
  text-decoration-color: rgba(54, 54, 54, 0.9);
  text-decoration-line: underline;
}
.userActions-navigation__dropdown-user-information li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: rgba(54, 54, 54, 0.9);
  margin-bottom: 12px;
}

.userActions-navigation__dropdown-user-information button {
  margin: auto;
  font-weight: 500;
}
.userActions-navigation__dropdown-user-information button:nth-of-type(1) {
  margin-top: 16px;
}
.userActions-navigation__dropdown-user-information button:nth-of-type(2) {
  margin-top: 24px;
}

/* SEARCH ICON STYLES */
.navigation-bar__user-actions-search {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigation-bar__user-actions-search-icon {
  cursor: pointer;
}
.navigation-bar__user-actions-search-active {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.5),
      rgba(219, 225, 241, 0.5)
    ),
    #ffffff;
  position: absolute;
  z-index: -1;
}

.navigation-bar__user-actions-search-inactive {
  display: none;
}
