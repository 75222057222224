.user-profile-icon {
  background: #67b3ad;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.large {
  width: 60px;
  height: 60px;
}

.small {
  width: 40px;
  height: 40px;
  padding-top: 1px;
  position: relative;
  margin-bottom: 0;
}

.navigation-bar__user-actions .active-open {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.5),
      rgba(219, 225, 241, 0.5)
    ),
    #ffffff;
  position: absolute;
  z-index: -1;
}

.hidden-selected {
  display: none;
}
