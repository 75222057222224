.appointment-detail__desktop-card .facility-estimate__estimate-section-container {
    flex-direction: column;
}
.appointment-detail__desktop-card .facility-estimate__estimate-section-emphasized {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.132px;
    color: #080707;
}
.appointment-detail__desktop-card .estimate__estimate-section-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: #000000;
}

.appointment-detail__desktop-card .accordion>.card>.card-header  {
    margin: auto;
    background-color: #F2F1EF;

}

.appts-small .accordion>.card>.card-header  {
    margin: auto;
}

/* DESKTOP ONLY */
@media screen and (min-width:768px) {
    .appointment-detail__desktop-card #payment-breakdown, 
    .appointment-detail__desktop-card .border-0, 
    .appointment-detail__desktop-card .card .estimate__payment-accordion-toggle {
    background-color: #F2F1EF!important;
}
}