.appointment-detail__desktop-section {
    background: #F2F1EF;
}

.appointment-detail__desktop-section .question-section__content {
    display: block;
}

.appointment-detail__desktop-section .question-section__details {
    padding-bottom: 0;
}

.appointment-detail__desktop-card .question-section {
    padding: 0;
    margin-bottom: 32px;
}

.appointment-detail__desktop-section .accordion .card {
    background-color: inherit;
}

.appointment-detail__desktop-section .question-section__details .body-1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: #080707;
}