.press-release__page-title {
  font-family: Crimson Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 71px;
  letter-spacing: 0.6px;
  margin-bottom: 20px;
  margin-top: 40px;
}

ul.press-release-list {
  list-style-type:none;
  padding: 0px;
  margin-bottom: 60px;
}

.press-release__press-release-image-wrap {
 height: 150px;
 min-width: 150px;
 text-align: center;
}

.press-release__press-release-image {
  height: 100%;
 }

.press-release {
  display: flex;
  margin-bottom: 40px;
}

.press-release__press-release-information {
  padding-left: 20px;
}

.press-release__press-release-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.6px;
}

.press-release__press-release-summary {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.6px;
  margin: 12px 0px;
}

.press-release__press-release-link {
  color: black;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.6px;
}

@media only screen and (max-width: 768px) {
  .press-release {
    flex-direction: column;
  }
}
