.save-estimate-text {
  font-size: 16px;
}

.save-estimate-heading {
  font-size: 60px;
  line-height: 67px;
  font-family: 'Crimson Pro', 'Times New Roman', Times, serif;
  font-weight: 900;
}

.save-your-estimate-modal .send-estimate-form section {
  display: flex;
  justify-content: center;
}

#share-feedback-container {
  display: none;
}

.send-estimate-form button.button {
  margin-top: 24px;
}

@media only screen and (max-width: 768px) {
  .save-estimate-heading {
    font-size: 36px;
  }

  .save-your-estimate-modal p {
    padding-right: 0px;
  }
}
