.page-content {
    padding: 0 64px 32px 64px;
}

.fancy-search__split-input {
    margin-bottom: 16px;
    margin-top: 50px;
    width: 50%;
}

.use-my-location-text {
    margin-bottom: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #4C6BBB;
    margin-left: 8px;
}
.relative-container {
    position: relative;
}

.results-container {
    background-color: #f2f1ef;
    min-height: 40vh;
}
.sort-container {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    padding-top: 22px;
    border-top: 1px solid rgba(0, 64, 60, 0.1);
}
.sort-container .dropdown {
    position: static;
}
.sort-filter {

}

.scroll-container {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
    gap: 3%;
    background-color:#F7F7F7;
    padding-top: 32px;
}

.hide-scroll::-webkit-scrollbar {
    background: transparent; /* make scrollbar transparent */
    width: 0px;
}
 :is(.page-content, .cost-page-content) .dropdown .filter-option {
    padding: 4px 12px;
    height: max-content;
    border: 0.8px solid #C6C6C6;
    border-radius: 20px;
    white-space: nowrap;
    cursor: pointer;
    color: #000000;
}
:is(.page-content, .cost-page-content) .dropdown .dropdown-toggle::after {
    display: none;
}
:is(.page-content, .cost-page-content) .dropdown-item.filter-item:hover {
    /* background-color: rgba(0,0,0,0.2) !important; */
    background: rgba(103, 179, 173, 0.1)!important;
  }
:is(.page-content, .cost-page-content) .dropdown-item.filter-item {
    padding: 19px 0px 19px 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
  }
:is(.page-content, .cost-page-content) .filter-dropdown {
    width: 266px;
}
  
:is(.page-content, .cost-page-content) .dropdown .btn-primary,
:is(.page-content, .cost-page-content) .dropdown .btn-primary:focus,
:is(.page-content, .cost-page-content) .dropdown .btn-primary:active,
:is(.page-content, .cost-page-content) .dropdown .btn-primary:hover,
:is(.page-content, .cost-page-content) .show>.btn-primary.dropdown-toggle:focus,
:is(.page-content, .cost-page-content) .show>.btn-primary.dropdown-toggle

 {
    background-color: transparent;
    border: 0.8px solid #C6C6C6;
    box-shadow: none;
    color: #000000;

}

:is(.page-content, .cost-page-content) .dropdown .filter-option-active
 {
    padding: 4px 12px;
    height: max-content;
    border-radius: 20px;
    white-space: nowrap;
    cursor: pointer;
    background: rgba(103, 179, 173, 0.2)!important;
    color: #00403C!important;
    border: 1.8px solid #018077!important;


}
:is(.page-content, .cost-page-content) .dropdown .btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
}

:is(.page-content, .cost-page-content) .dropdown .btn-primary:not(:disabled):not(.disabled):active {
    background-color: transparent;
    border: 0.8px solid #C6C6C6;
    box-shadow: none;
    color: #000000;
}


.filter-option {
    padding: 4px 12px;
    height: max-content;
    border: 0.8px solid #C6C6C6;
    border-radius: 20px;
    white-space: nowrap;
    cursor: pointer;
    color: #000000;
    margin-right: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.5px;

}

.filter-option-active {
    padding: 4px 12px;
    height: max-content;
    border-radius: 20px;
    white-space: nowrap;
    cursor: pointer;
    background: rgba(103, 179, 173, 0.2);
    color: #00403C;
    border: 1.8px solid #018077;
    margin-right: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.5px;

}

.filter-option-invalid {
    color: #868686;
    cursor: none;
    padding: 4px 12px;
    height: max-content;
    border: 0.8px solid #C6C6C6;
    border-radius: 20px;
    white-space: nowrap;
    margin-right: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.5px;

}
/* Service Not Found  */
.service-not-found-text {
    font-family: 'Crimson Pro';
    font-style: normal;
    font-weight: 200;
    font-size: 60px;
    line-height: 67px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.353796px;
    padding-top: 113px;
    padding-bottom: 88px;
    max-width: 896px;
    margin: auto;
}

.service-not-found-btn {
    display: block;
    margin: auto;
    cursor: pointer;
}

.service-not-found-footer {
    width: 98.8vw;
    margin-left: -64px;
    margin-bottom: -120px;
    position: relative;
    z-index: 1;
    margin-top: 250px;
}

.overlapping-footer {
    z-index: 995;
    position: relative;
}

.sticky-footer {
    position:absolute;
    bottom: 0;
    width: 100%;
}
.results__container-tag {
    padding: 4px 16px;
    background-color: #DBE1F1;
    border-radius: 20px;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #080707;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-right: 19.88px;   
}

.results-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: #000000;
    padding-left: 64px;
    padding-top: 32px;
}
/* CARD STYLES */
.savings-card {
    padding: 16px 32px;
    margin-bottom: 24px;
}
.card-result {
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    position: relative;
}

.card-result::after {
    content: '';
    width: calc(100% + 64px);
    border-bottom: 0.5px solid #D8D8D8;
    height: 0.5px;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: -32px;
    display: inline-block;
}
.card-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 24px;
}

.card__detail-distance {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    color: #000000;

}

.card__detail-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #080707;
    max-width: 19em;
}

.card__detail-tag {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.4px;
    color: #262525;
}

/* SEE MORE ESTIMATES */

.showing-count-text {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5px;
}

.see-more-estimates {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #080707;
    border: none;
    margin: auto;
    display: block;
    background-color: #f2f1ef;
}

.see-more-progress .progress {
    background-color: #C4C4C4;
    width: 343px;
    max-width: 100%;
    margin: auto;
    height: 4px;
    margin-bottom: 30px;
}
.see-more-progress .progress-bar {
    background-color: #080707
    
}

/* MOBILE STYLES */
.absolute-container {
    padding: 16px;
    display: flex;
    flex-direction: column;
    background: #F2F1EF;
    min-height: 100vh;
}
.absolute-container .mobile-search__close {
    margin: 24px 0;
    align-self: flex-end;
   
}
.mobile-search__info-panel-container .mobile-search__close {
    margin: 24px 0 32px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400!important;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 160% */

    letter-spacing: 0.9px;
    text-transform: uppercase;

    /* Color/4. Text/Black */

    color: #080707;
   
}

.facility-estimate__estimate-callout .mobile-search__close {
    margin: 24px 0 48px 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400!important;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 160% */

    letter-spacing: 0.9px;
    text-transform: uppercase;

    /* Color/4. Text/Black */

    color: #080707;
    margin-top: 40px;
    padding-top: 40px;
   
}

.absolute-container .mobile__search-results {
    margin: 235px auto 100px auto;
}

.mobile-search__info-panel-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 16px 0px;
    gap: 24px;
    
    background: #DBE5E5;
}

.mobile-search__info-panel-estimates {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #000000;
}

.mobile-search__info-panel-servicename {
    font-family: 'Crimson Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 31px;
    display: flex;
    align-items: center;
    letter-spacing: 0.353796px;
    text-decoration-line: underline;
    color: #00403C;
}
.sort-modal  {
    max-width: 100%!important;
    position: absolute!important;
    margin-left: -24px!important;
    bottom: 0;
    width: 100vw!important;
    min-height: fit-content!important;
    margin-bottom: 0!important;
}
.mobile-sort-container {
    display: flex;
    border:0;
    flex-direction: column;
    justify-content: center;
    background-color: #FFFFFF;
    border-radius: 20px 20px 0px 0px;

    position: absolute;
    bottom: 0;
    width: 100vw;
    max-width: 100%;
    margin-left: -16px;

    backdrop-filter: opacity(20%);
}

.mobile-sort-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    padding-left: 16px;
    padding-top: 22px;
    margin-bottom: 27px;
}

.mobile-sort-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.132px;
    color: #363636;
}

.mobile-sort-options {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;
    padding-inline: 16px;
    margin-bottom: 37px;
}

.mobile-sort-container .sort-btn {
    margin-bottom: 40px;
}

.mobile-sort-radio:checked {
    accent-color: #000000;
}
.mobile-sort-options input[type=radio] {
    transform: scale(1.7);
}

.request-a-service {
    margin-bottom: 16px;
}

.request-a-service-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    display: flex;
    align-items: center;
    letter-spacing: 0.132px;
    color: #000000;
}
.request-service-error-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 160% */

    display: flex;
    align-items: center;
    letter-spacing: 0.5px;

    /* Error */

    color: #D94411;

    
}


.modal-body .request-a-service-btn {
    margin-top: 52px;
    margin-bottom: 44px;
}
@media screen and (max-width:768px) {
    .savings-card {
        padding: 16px;
        margin-bottom: 8px;
        padding-bottom:0px;
    }

    .page-content {
        padding: 0px;
    }

    .card-result::after {
        width: calc(100% + 32px);
        margin-left: -16px;
    }
    .card-title {
        font-size: 14px;
        line-height: 21px;
    }

    .card__detail-distance {
        font-size: 12px;
        line-height: 18px;
    }

    .card__detail-name {
        font-size: 18px;
        line-height: 24px;
    }

    .card__detail-tag {
        font-size: 15px;
        line-height: 22px;
    }

    .results-title {
        padding: 16px;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        background: white;
        margin-bottom: 0px;
        border: 1px solid rgba(135, 135, 135, 0.2);
      
    }
    .service-not-found-text {
        font-size: 36px;
        line-height: 40px;
        letter-spacing: 0.353796px;
    }
    .service-not-found-footer {
        width: 100vw;
        margin-left: -16px;
        position: fixed;
        bottom: -20px;
        z-index: 1;
        margin-top: 0px;
        margin-bottom: 0px;
    
    }
    .filter-option-invalid, .filter-option, .filter-option-active {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        /* identical to box height */

        letter-spacing: 1.25px;
    } 
    .facility-estimate__estimate-callout .mobile-search__close {
        margin-top: 0px;
        padding-top: 0px
    }
    .results-container {
        min-height: 50vh;
    }
}
