footer {
  overflow: hidden;
  background-color: #333333;
}

.footer-container {
  margin: auto;
  padding: 30px 30px 80px 30px;
  overflow-x: hidden;
}

.footer-row {
  display: flex;
}

.footer-icons {
  justify-content: space-evenly;
  width: 150px;
  margin: auto;
}

.footer-links {
  justify-content: space-evenly;
  max-width: 500px;
  margin: auto;
  margin-bottom: 24px;
}

span.footer-title {
  color: white;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 23px;
}

a.footer-link {
  color: white;
  text-decoration: none !important;
  font-size: 13px;
  letter-spacing: 0.25px;
  line-height: 20px;
}

a.footer-link:hover {
  color: white;
  text-decoration: none !important;
  font-size: 13px;
  letter-spacing: 0.25px;
  line-height: 20px;
}

@media only screen and (max-width: 768px) {

  .footer-container {
    padding: 30px 30px 40px 30px;
  }

  .footer-links {
    flex-direction: column;
    text-align: center;
  }

}
