#estimate-pdf-container {
  display: none;
  width: 816px;
}

.estimate-pdf .estimate__estimate-callout {
  background-color: white;
  margin-bottom: 0px;
  padding-top: 40px;
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 32px;
}

.estimate-pdf .estimate__callout-main-text,
.estimate-pdf .estimate__estimate-callout-overline-text {
  color: #080707;
  max-width: 100%;
}

.estimate-pdf .estimate__estimate-width.estimate__extra-pdf-padding {
  padding-bottom: 32px;
}

.estimate-pdf .cost-and-coverage .estimate__estimate-section-emphasized,
.estimate-pdf .estimate__estimate-section-emphasized {
  width: 100%;
}

.estimate-pdf .estimate__estimate-section {
  margin-bottom: 0px;
  padding-top: 0px;
  padding-left: 36px;
  padding-right: 36px;
}

.estimate-pdf .estimate__more-facilities {
  background-color: white;
  margin-top: 0px;
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 52px;
}

.estimate-pdf .container-with-top-padding {
  border: none;
}

.estimate-pdf .estimate__estimate-section .estimate__estimate-section-container {
  padding-bottom: 24px;
}

.estimate-pdf .estimate__estimate-section .estimate__estimate-section-container div {
  width: 100%;
}

.estimate-pdf .estimate__estimate-section .estimate__estimate-section-container .estimate__estimate-section-emphasized-container {
  padding-right: 8px;
}

.estimate-pdf .estimate__facility-details {
  width: 60%;
}

.estimate-pdf .estimate__more-facilities-text {
  padding: 0px 16px 0px 0px;
  width: 100%;
  margin-bottom: 0px;
}

.estimate-pdf ul.estimate__facilities-list {
  width: 100%;
  margin-bottom: 0px;
}

.estimate-pdf .estimate__facilities-list li {
  padding: 0px;
}

.estimate-pdf .estimate__facility-name {
  padding-right: 8px;
}

#estimate-pdf-2 {
  padding-top: 36px;
}

/* .estimate-pdf .where-youll-be {
  padding-top: 40px;
} */
