a.article-card__link {
  color: black;
}

a.article-card__link:hover {
  color: black;
  text-decoration: none;
}

.article-card__article-image-container {
  position: relative;
  min-width: 240px;
  height: 160px;
  overflow: hidden;
}

.article-card__article-image {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  min-height:100%;
  min-width:100%; 
  -webkit-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}

.article-card__article-title {
  font-size: 24px;
  line-height: 32px;
}

.article-card__body {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 24px;
  margin-bottom: 32px;
  border-radius: 8px;
}

.article-card__article-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 8px;
  letter-spacing: 0.6px;
}

.article-card__article-date-and-keywords {
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.6px;

  margin-bottom: 16px;
}

.article-card__article-date-and-keywords .article-card__article-keywords {
  font-style: italic;
}

.article-card__article-author {
  display: flex;
  align-items: center;
}

.article-card__author-icon {
  background-color: #C4C4C4;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
}

.article-card__author-name {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.6px;
}

@media only screen and (max-width: 768px) {
  .article-card__body {
    flex-direction: column-reverse;
    padding: 0px;
    margin: 0px 0px 24px 0px;
    border-radius: 4px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  }

  .article-card__article-information {
    padding: 16px 24px 24px 24px;
  }

  .article-card__article-image-container {
    position: relative;
    min-width: 100%;
    height: 160px;
    overflow: hidden;
    border-radius: 4px 4px 0px 0px;
  }

  .article-card__article-image {
    position: absolute;
    left: 50%;
    top: 50%;
    height: auto;
    width: 100%;
    min-height:100%;
    min-width:100%; 
    -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
  }

  .article-card__article-title {
    font-size: 18px;
    line-height: 27px;
  }
}
