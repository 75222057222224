.input-container {
  font-family: Poppins;
  position: relative;
}

.input-container div,
.input-container label {
  width: 100%;
}

.input-container div input,
.input-container select {
  background-color: transparent;
  color: #080707;
  font-family: Poppins;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.4px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #080707;
  padding: 8px 24px 8px 16px;
  outline: none;
  border-radius: 0px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 4rem;
}

.input-container select::-ms-expand {
  display: none;
}

.input-container div input.writeable::placeholder {
  color: #363636;
}

.input-container div input.read-only::placeholder {
  color: #848484;
}

.input-container div input.read-only {
  border-color: #848484;
  cursor: default;
}
.input-container div input.read-only-alt {
  border-bottom: 1px solid #585858;
  color: #585858;

}
.input-and-label {
  position: relative;
  padding-top: 20px;
}

.input-container .input-and-label label {
  display: none;
  position: absolute;
  top: 0px;
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.input-container .input-and-label input.writeable:focus + label,
.input-container .input-and-label input.writeable:valid + label,
.input-container .input-and-label input.read-only:not(:placeholder-shown) + label {
  display: block;
}

.select-only {
caret-color: transparent;
cursor: pointer;
}

.input-container .input-and-label input.writeable:focus::placeholder {
  color: transparent;
}

.input-container.placeholder-gray-bg div input,
.input-container.placeholder-gray-bg select {
  background: #EAEAEA;
}

.input-container.placeholder-gray-bg div input,
.input-container.placeholder-gray-bg select {
  padding: 8px 24px;
}

.input-container .input-icon {
  position: absolute;
  z-index: 0;
  right: 12px;
  top: 30px;
}

.input-container .input-icon .input-arrow {
  content: '';
  display: block;
  width: 0; 
  height: 0; 
  top: 36px;
  right: 24px;

  border: solid #080707;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.input-container .input-arrow.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.input-container .input-arrow.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.input-message {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  padding-top: 8px;
  padding-left: 16px;
  margin-top: -1px;
  position: absolute;
  z-index: 1;
}

.input-message.error {
  color: #D94411;
  border-top: 1px solid #D94411;
}

.input-message.error a {
  color: #D94411;

}


.input-message.valid {
  color: #018077;
  border-top: 1px solid #018077
}

.input-message.valid a {
  color: #018077;
  
}

.input-action {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.15px;
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .input-message {
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.25px;
  }
}

