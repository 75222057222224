input.form-control.contact-form__input,
textarea.form-control.contact-form__input  {
  border: 0.5px solid #000000;
  border-radius: 8px;
  font-family: Poppins;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.132px;
  padding: 12px;
}

textarea.form-control.contact-form__input  {
  min-height: 173px;
}

.contact-form__btn {
  background: #737272;
  padding: 8px 24px;
  margin-top: 40px;
  width: 287px;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 1.25px;
}

.contact-form__disclaimer {
  font-style: italic;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.132px;
  margin-top: -12px;
}

.contact-form__container .form-group label {
  display: none;
}

.contact-form__container .form-group {
  margin-bottom: 20px;
}

.contact-form__container button.button {
  margin-top: 40px;
}

.contact-form__thank-you-text {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.132px;
  margin-bottom: 60px;
}

@media only screen and (max-width: 768px) {
  .contact-form__thank-you-text {
    margin-top: 0px !important;
    margin-bottom: 40px;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.132px;
  }
}
