.search-page {
  background-color: #f8f9fa;
  height: 100%;
}

.search-page__service-groups-list,
.search-page__most-searched-list {
  margin: 0;
  padding: 0;
}

.search-page__most-searched-title {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.6px;
  font-weight: 900;
  padding: 12px;
  margin: 0;
}

.search-page__service-input div {
  width: 100%;
}

.search-page__service-input input {
  width: 100%;
  border: none;
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.5);
  padding: 40px 0px 20px 80px;
  background-color: white;
  

  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
}

.search-page__service-input input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #979797;
  opacity: 1; /* Firefox */
}

.search-page__back-arrow {
  border: solid black;
  border-width: 0 2.5px 2.5px 0;
  display: inline-block;
  padding: 2.5px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  height: 2.5px;
  width: 2.5px;
  margin-right: 40px;

  position: absolute;
  z-index: 999;
  top: 50px;
  left: 40px;
}

.search-icon {
  position: absolute;
  right: 40px;
  top: 45px;
}

.service-list-item.service-serch-list-item {
  padding: 16px;
}
