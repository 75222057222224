.sign-up__side-bar {
  background-color: #DBE1F1;
  padding: 56px 24px;
  width: 270px;
  min-width: 270px;
}

.sign-up__side-bar-text {
  position: fixed;
  width: 270px;
  padding: 0px 24px;
  left: 0;
}

.sign-up__side-bar img {
  position: fixed;
  bottom: 0;
  left: -3px;
}

.sign-up__side-bar h2 {
  font-weight: 200;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 0.353796px;
  margin-bottom: 40px;
}

.sign-up__content {
  max-width: 714px;
  margin: 0px auto;
}

.sign-up__content h1 {
  font-weight: 900;
  font-size: 43px;
  line-height: 48px;
  letter-spacing: 0.25px;
  margin-bottom: 16px;
}

.sign-up__subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
}

.consent-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: inline-block;
  margin-left: 12px;
  margin-bottom: 0;
}

.sign-up-form__input {
  margin-bottom: 48px;
}

.sign-up-form__input.with-help-text {
  margin-bottom: 56px;
}

.sign-up-form__consent-input {
  margin-top: 54px;
  margin-bottom: 48px;
  display: flex;
  justify-content: center;
}

.sign-up-form__consent-input input {
  height: 18px;
  width: 18px;
  min-width: 18px;
}

.access-code__input {
  margin-bottom: 16px;
  margin-top: 50px;
}

button.build-profile-btn {
  margin-top: 80px;
}

.sign-up-form__link {
  color: #080707;
  text-decoration: underline;
}

.access-code-helper-container {
  width: 100%;
  text-align: center;
  margin-top: 16px;
}

.access-code-helper {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.access-code-helper.sent {
  color: #018077;
}

.my-modal .modal-content {
  width: 500px!important;
  height: 319px;
  max-width: none !important;
  border-radius: 20px!important;

}
.my-modal .modal-body {
  padding-top: 0px!important;
  padding-left: 0px!important;
  padding-bottom: 0px!important;
  padding-right: 0px!important;
}
.sign-up__modal-body {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 32px;

}
.sign-up__modal-body button:first-of-type{
  margin-bottom: 24px;
}
.sign-up__modal-header{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 27px;
color: #000000;
letter-spacing: 0.5px;
}

.sign-up__modal-text{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 24px;
margin-bottom: 56px;
/* or 160% */

letter-spacing: 0.5px;

/* Color/4. Text/Grey */

color: #363636;
}

@media only screen and (max-width: 768px) {
  .sign-up__content h1 {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.25px;
  }

  .sign-up__side-bar h2 {
    font-weight: 200;
    font-size: 24px;
    line-height: 27px;
    letter-spacing: 0.353796px;
  }

  .sign-up__side-bar-text {
    position: absolute;
    width: 160px;
    padding: 0px;
    left: 16px;
  }
  
  .sign-up__side-bar img {
    position: absolute;
    height: 250px;
    width: 250px;
    bottom: -24px;
    right: -20px;
    left: unset;
  }

  .sign-up-form__consent-input {
    margin-top: 50px;
    margin-bottom: 44px;
  }

  .sign-up__subtitle {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .sign-up__modal-body {
    padding: 8px 8px 16px;
  }
}

