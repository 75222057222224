.about-us--1,
.about-us--2, 
.about-us--3 {
  padding: 100px 0px;
}

.about-us--1 {
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 80%;
  
}

.about-us--2 {
  text-align: center;
  background-color: #080707;
}

.about-us--2 p {
  color: white;
}

.about-us--2 section {
  max-width: 600px;
  margin: auto;
}

.about-us--2 section .about-us-heading,
.about-us--2 section .text-large {
  letter-spacing: 1px;
}

.about-us--3 section {
  max-width: 750px;
  text-align: center;
  margin: auto;
}

.about-us-heading {
  font-size: 43px;
  line-height: 48px;
  font-weight: 500;
  padding-bottom: 40px;
}

.about-us-heading-container {
  width: 450px;
}

.about-us-text {
  width: 550px;
}

@media only screen and (max-width: 768px) {

  .about-us--1,
  .about-us--2, 
  .about-us--3 {
    padding: 100px 40px;
  }

  .about-us--1 {
    flex-direction: column;
    max-width: 100%;
  }

  .about-us--1 .about-us-heading { 
    font-size: 36px;
  }

  .about-us-heading-container,
  .about-us-text {
    width: 100%;
  }
  

}
