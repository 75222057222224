.onboarding__content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

  
.onboarding__landing-header {
    margin-bottom: 61px;
    margin-top:24px;
    font-family: 'Crimson Pro';
    font-style: normal;
    font-weight: 200;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.353796px;
    color: #080707;
    

}

.onboarding__landing-header-secondary {
    margin-bottom: 24px;
    margin-top: 16px;
    font-family: 'Crimson Pro';
    font-style: normal;
    font-weight: 200;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.353796px;
    color: #080707;
    

}
.onboarding__content-box .width-694 {
    max-width: 694px;
}

/* notifications */
.onboarding__notifications-content-box {
    max-width: 634px;
    margin: 0px auto;
    margin-top: 48px;
    margin-bottom: 88px;
}

.onboarding__notifications-subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 8px;
    letter-spacing: 0.15px;
    
    color: #000000;
}
.notifications-subtitle{
    margin-bottom: 24px;

}
.onboarding__notification-option {
    margin-bottom: 32px;
}

.onboarding__content-box {
    max-width: 754px;
    margin: 0px auto;
  }

.onboarding__container-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    padding-inline: 9px;

}
.onboarding__container-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 0.5px solid #D8D8D8;
    max-width: 559px;



    

}


.onboarding__container-row:last-of-type {
    margin-bottom: 64px;
}

.onboarding__container-icon {
    margin: 1.75rem 1.75rem 1.75rem 0;
}
.onboarding__container-textbox {
    display: flex;
    flex-direction: column;
  

}
.onboarding__container-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.132px;

    color: #000000;
    margin-bottom: 0;
}

.txt-fw {
    font-weight: 500;
}
.onboarding__container-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: 0.132px;

    color: #000000;

    margin-bottom: 21px;
}


.onboarding__landing-footer-container {
    position: relative;
    margin-top: 300px;
    width: 98vw;
    max-height: 25vh;
    display: none;
}
.onboarding__landing-footer-container-stepFive {
    position: relative;
    margin-top: 16px;
    width: 99vw;
    max-height: 25vh;
}

.onboarding__landing-minion {
    position: absolute;
    bottom: 0;
    right: 8%;
    max-width: 100%;
}
.onboarding__landing-wave {
    width: 98vw;
    max-height: 25vh;
    
}

.onboarding__landing-mobile-minion {
    width: 100vw;
}

.onboarding__textbox-primary .body-1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;

    /* Color/4. Text/Black */

    color: #080707;
}

ul.onboarding__bullet-list {
    list-style-type: disc;
    padding-left: 2em;
    margin-bottom: 40px;
}

ul.onboarding__bullet-list p {
    display: inline-block;
    position: relative;
    left: -4px;
  }

.landing__final-p {
    margin-bottom: 80px;
}

.onboarding__step1-form {
    max-width: 636px;
    margin-inline: auto;
    margin-bottom: 80px;
    margin-top: 40px;
}

.onboarding__custom-text-button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #4C6BBB;
    background-color: transparent;
    border: none;
    display: block;
    margin: auto;
    margin-bottom: 40px;
    cursor: pointer;
}

.onboarding__custom-text-button p:not(.location-text-enabled) {
    color: #4C6BBB;
    

}
.onboarding__custom-button {
    border: 1.5px solid #018077;
    border-radius: 20px;
    padding: 8px 24px;
    background-color: transparent;
    cursor:pointer;
    margin: auto;
    display: block;

    font-size: 13px;
    line-height: 20px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;

    /* Color/4. Text/Black */

    color: #080707;
    margin-top: 68px;
}
.disabled {
    color: #868686;
    border: 1.5px solid #C6C6C6;
    cursor: not-allowed;
}

.location-text-enabled {
    color: #018077;
}
/* step 5 */
.onboarding-body-1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* or 160% */

    letter-spacing: 0.5px;

    /* Color/4. Text/Black */

    color: #080707;
}
.onboarding__step5-content {
    margin-bottom: 42px;
}
.onboarding__step5-subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.5px;
    margin-top: 40px;
    margin-bottom: 32px;
    color: #000000;
    
}
.onboarding__checkup-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.132px;
    /* this will change once the facility text is added to the bottom */
    /* margin-bottom: 55px;  */

    color: #000000;
}

.onboarding__step5-content-box {
    justify-content: space-between;
    border-bottom: 0.5px solid #D8D8D8;
    margin-bottom: 16px;
}   
.onboarding__best-price {
    padding: 4px 16px;
    background-color: #DBE1F1;
    border-radius: 20px;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #080707;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-right: 19.88px;    
}
.onboarding__cpt-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    margin-bottom: 3px;
    color: #000000;
}
.onboarding__passavant {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.4px;

    color: #262525;
}

.onboarding__button {
    z-index: 5;
}
.FAQ-link, .FAQ-link:hover {
    color: inherit;
    text-decoration: underline;
}
/* desktop */

@media only screen and (min-width: 768px) {
    .onboarding__landing-header {
        font-family: 'Crimson Pro';
        font-style: normal;
        font-weight: 200;
        font-size: 45.94px!important;
        line-height: 51px;
        margin-bottom: 52px;
        letter-spacing: 0.353796px;
        max-width: 610px;

    
    }
    .onboarding__landing-header-secondary {
        font-family: 'Crimson Pro';
        font-style: normal;
        font-weight: 200;
        font-size: 45.94px!important;
        line-height: 51px;
        margin-top: 24px;
        margin-bottom: 16px;
        letter-spacing: 0.353796px;
        max-width: 610px;
        color: #080707;
    }
    .onboarding__container-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.132px;
        color: #000000;
    }

    .onboarding__container-row {
        padding-inline: 97px;
        max-width: 754px;

    }
    
    .onboarding__textbox-primary .body-1{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.5px;
        max-width: 634px;
    
        /* Color/4. Text/Black */
    
        color: #080707;
    }
    .onboarding__landing-mobile-minion {
        display: none;
    }
    
    
    .onboarding__landing-footer-container {
        display: block;
    }

    .onboarding__notifications-subtitle {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;
        
        letter-spacing: 0.132px;
        
        color: #000000;
    }
    .onboarding__notification-option {
        max-width: 482px;
    }
    
    .txt-fw {
        font-weight: 600;
    }
    /* step five */

    .onboarding-body-1 {
        font-size: 18px;
        line-height: 27px;
    }
    .onboarding__step5-content-box {
        margin-bottom: 32px;
    }
    .onboarding__step5-content {
        max-width: 636px;
        margin: auto;
        margin-bottom: 71px;
    }
    
    .onboarding__step5-subtitle {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;
        letter-spacing: 0.132px;

        color: #000000;
    }
    .onboarding__cpt-text {
        margin-bottom: 4px;
    }

    .onboarding__checkup-text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.132px;
        margin-bottom: 8px;

        color: #000000;
    }

    .onboarding__best-price {
        padding: 4px 16px;
        background-color: #DBE1F1;
        border-radius: 20px;

        font-family: 'Poppins';
        font-style: normal;
        font-size: 14px;
        line-height: 21px;
        margin-right: 8.88px; 
    }
    .onboarding__passavant {
        font-size: 18px;
        line-height: 27px;
    }
    .onboarding__landing-footer-container-stepFive {
        margin-top: 200px;
    }
}
/* very small screens/mobile */
@media only screen and (max-width: 480px) {
    .onboarding__landing-header {
        max-width: 343px;

    }
   
}
/* minion movement control (onboarding screen 1) */
@media only screen and (max-width: 1200px) {

    .onboarding__landing-minion {
        position: absolute;
        bottom: 0;
        right: 3%;
        max-width: 100%;
    }
    }