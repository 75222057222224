button.filter {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #080707;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 18px;
  padding: 6px 25px;
  border-radius: 25px;
  border: 1px solid black;
  margin: 0px;
}

.all-services-heading .dropdown .dropdown-menu.filter-dropdown {
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.3);
  border: none;
  margin: 5px 0px !important;
  border-radius: 24px;
  background-color: white;
}

.dropdown-item.filter-item:hover {
  background-color: rgba(0,0,0,0.2) !important;
}

.filter.dropdown-toggle::after {
  border: solid #979797;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.currently-selected {
  background-color: rgba(103, 179, 173, 0.2)!important;
  font-family: 'Poppins'!important;
  font-style: normal!important;
  font-weight: 600!important;
  font-size: 18px!important;
  line-height: 27px!important;
  letter-spacing: 0.132px!important;
  color: #018077!important;

}

.fancy-search-sort {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
}