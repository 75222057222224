.glossary {
  padding-top: 40px;
  padding-bottom: 40px;
}

.glossary-body {
  display: grid;
  grid-template-columns: 375px 1fr;
  column-gap: 24px;
}

.glossary-description {
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.13px;
}

.glossary-term__term {
  font-weight: 700;
}

.glossary-search-input.form-control {
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
  border-radius: 24px;
  margin: auto;
  padding: 10px 15px;
  height: auto;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.3);
}

.glossary-search-input.form-control:focus {
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.3);
  border: none;
}

@media only screen and (max-width: 768px) {

  .glossary-body {
    display: block;
    padding: 0px 40px;
  }

  .glossary-heading {
    font-size: 48px !important;
    line-height: 53px !important;
    padding: 70px 40px 24px 40px;
  }

  .glossary-information {
    margin-bottom: 40px;
  }

  .glossary-description {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.6px;
  }

  .glossary {
    padding-top: 0px;
    padding-bottom: 100px;
  }


}
