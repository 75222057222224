@import url('https://fonts.googleapis.com/css2?family=Poppins');

select.form-control {
  border: none;
  font-size: 16px;
  color: black;
  padding:0px;
  font-family: Poppins;
  width: fit-content;
  background-color: white;
  /* Hide default select arrow for Firefox */
  -moz-appearance: none;
  /* Hide default select arrow for Chrome */
  -webkit-appearance: none;
}

/* Hide default select arrow for IE10 */
select::-ms-expand {
  display: none;
}

.service-page-select {
  background-color: silver;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.3);
  letter-spacing: 0.5px;
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid black;
  color: black;
}

.quote-options-select.form-control {
  background-color: #D8D8D8;
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
  padding-left: 20px;
  padding-right: 33px;
  border-bottom: 1px solid #979797;
  color: black;
}

.quote-options-select.form-control:valid,
.quote-options-select.form-control:focus {
  background-color: #D8D8D8;
  background-image: none !important;
  border-bottom: 1px solid #979797 !important;
}

.quote-options-select.form-control:invalid {
  background-image: none !important;
}

select:required:invalid {
  color: #979797;
}

.quote-options-select::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #979797;
  opacity: 1; /* Firefox */
}

.quote-options-select:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #979797;
}

.quote-options-select::-ms-input-placeholder { /* Microsoft Edge */
  color: #979797;
}

