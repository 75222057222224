.sidebar-layout {
  height: fit-content;
  min-height: 100%;
  display: block;
}

.sidebar-layout__body-default {
  display: flex;
  min-height: calc(100vh - 80px);
}

.sidebar-layout__body-sign-in {
  display: flex;
  min-height: 80vh;
}

.sidebar-layout__sidebar {
  position: relative;
  z-index: -1;
}

.sidebar-layout__content-container {
  height: 100%;
  flex-grow: 1;
}

.sidebar-layout__content {
  height: fit-content;
  width: 100%;
  padding: 64px 32px;
}

.handl-progress-bar .progress-bar {
  background-color: #0F3544CC;
  border-radius: 0;

}
@media only screen and (max-width: 768px) {
  .sidebar-layout__body-default {
    flex-direction: column;
    min-height: calc(100vh - 60px);
  }

  .sidebar-layout__sidebar {
    width: 100%;
    height: 280px;
    padding: 24px 16px;
    order: 2;
    overflow: hidden;
  }

  .sidebar-layout__content {
    order: 1;
    padding: 92px 16px;
    margin: 0px;
  }

  .handl-progress-bar  {
    position: fixed;
    top: 55px;
    right:0;
    left: 0;
  
  }
}
